import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, NgForm, Validators } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BreakLogDataModel } from '../../../../_models/break-log-data-model';
import { EmployeeDataModel } from '../../../../_models/employee-data-model';
import { UserDataModel } from '../../../../_models/user-data-model';;
import { LeaveService } from '../../../../_services/leave.service';

@Component({
  selector: 'app-apply-breaks',
  templateUrl: './apply-breaks.component.html',
  styleUrls: ['./apply-breaks.component.css']
})
export class ApplyBreaksComponent implements OnInit {
  @Output() reloadMyBreaks: EventEmitter<any> = new EventEmitter();
  breakObj: BreakLogDataModel;
  userObj: UserDataModel;
  empObj: EmployeeDataModel;
  userData: any;
  date: Date;
  today: any;
  tomorrow: any;
  hoursDifference: any;
  minutesDifference: any;
  breakFormSubmitted: boolean = false;
  message: string = "";
  msgFlag: boolean = false;

  constructor(private leaveService: LeaveService,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.getData(); // getting data from local storage api and setting it in our local variable.
  }

  async getData() {
    this.spinner.show();
    this.breakObj = new BreakLogDataModel();
    this.userObj = new UserDataModel();
    this.empObj = new EmployeeDataModel(); // will be used in future to get employee data.

    this.breakFormSubmitted = false;
    this.msgFlag = false;
    this.breakObj.day = "today";
    this.today = new Date();
    this.today = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate();
    await this.storage.get('empDetails').subscribe((empApplyBreak:any) => {
      this.userData = empApplyBreak.userProfile;
      this.userObj.ID = this.userData.userId;
      this.userObj.email = this.userData.email;
      this.userObj.fullName = this.userData.fullName;

      this.empObj.employee = this.userObj;
      this.empObj.ID = this.userObj.ID;
      this.breakObj.employee = this.empObj;

      this.breakObj.ID = this.userObj.ID;

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  onChangeDay(val: any) {
    this.breakObj.day = val;
  }

  async onSubmitBreak(form: FormGroup) {
    this.msgFlag = false;
    this.breakFormSubmitted = true;
    // if (!form.value.outtime) {
    //   form.controls['outtime'].setValidators([Validators.required]);
    //   form.controls['outtime'].updateValueAndValidity();
    //   form.controls['outtime'].setErrors({ 'invalid': true });
    // }
    if (!form.value.outtime) {
      form.controls['outtime'].setValidators(Validators.required);
      form.controls['outtime'].updateValueAndValidity();
      return;
    }


    if (!form.value.returntime) {
      form.controls['returntime'].setValidators([Validators.required]);
      form.controls['returntime'].updateValueAndValidity();
      return;
    }

    if (!form.value.reason) {
      form.controls['reason'].setValidators([Validators.required]);
      form.controls['reason'].updateValueAndValidity();
      return;
    }

    if (form.invalid) {
      this.spinner.hide();
      return true;
    }
    this.spinner.show();
    var data = form.value;

    if (data.day == "tomorrow") {
      this.date = this.getTomorrowDate();
    }
    else {
      this.date = this.today;
    }

    this.breakObj.outtime = new Date(this.date + ' ' + data.outtime);
    this.breakObj.returntime = new Date(this.date + ' ' + data.returntime);

    // checking for break time validity.
    if (this.breakObj.outtime > this.breakObj.returntime) {
      this.spinner.hide();
      this.msgFlag = true;

      this.message = "Return time cannot be less than out time.";
      form.value.returntime = undefined;
      form.value.outtime = undefined;
      form.controls['outtime'].setValue(null);
      form.controls['outtime'].updateValueAndValidity();
      form.controls['reason'].setValidators(null);
      form.controls['reason'].updateValueAndValidity();
      // form.reset();
      return;
    }

    this.breakObj.breakduration = this.getDifferenceBetweenTimes(this.breakObj.outtime, this.breakObj.returntime);
    this.breakObj.reason = data.reason;
    this.breakObj.employee.employee.ID = this.userObj.ID;

    await this.leaveService.postNewBreak(this.breakObj).subscribe((res: any) => {
      if (res.ResponseCode == 100) { // success
        this.toastr.success(res.Message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        location.reload();
        this.reloadMyBreaks.emit();
      }
      else if (res.ResponseCode == 999) {
        this.toastr.error(res.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  getTomorrowDate() {
    this.tomorrow = new Date(this.today);
    this.tomorrow.setDate(this.tomorrow.getDate() + 1)
    this.tomorrow = this.tomorrow.getFullYear() + '-' + (this.tomorrow.getMonth() + 1) + '-' + this.tomorrow.getDate();
    return this.tomorrow;
  }

  getDifferenceBetweenTimes(start, end) {
    var difference = end.getTime() - start.getTime();

    this.hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= this.hoursDifference * 1000 * 60 * 60;

    this.minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= this.minutesDifference * 1000 * 60;

    // return hoursDifference + ' hour/s ' + minutesDifference + ' minute/s';
    if (this.hoursDifference < 10) {
      this.hoursDifference = '0' + this.hoursDifference;
    }
    if (this.minutesDifference < 10) {
      this.minutesDifference = '0' + this.minutesDifference;
    }
    return this.hoursDifference + ':' + this.minutesDifference;
  }

  //reset form.
  resetForm() {
    this.breakObj = null;
    this.breakFormSubmitted = false;
    this.getData();
  }
  onChange(event: any) {
    // if (event.form.controls['day'].value == "tomorrow") {
    //   this.date = this.getTomorrowDate();
    // }
    // else {
    //   this.date = this.today;
    // }
    // this.breakObj.outtime = new Date(this.date + ' ' + event.form.controls['outtime'].value);
    // this.breakObj.returntime = new Date(this.date + ' ' + event.form.controls['returntime'].value);
    // if (this.breakObj.outtime  > this.breakObj.returntime) {
      this.msgFlag = false;
    // }
  }
}
