import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { navItemsEmp, navItemsCust, navItems } from '../../_nav';
import { StorageMap } from '@ngx-pwa/local-storage';
import { EmployeeDataModel } from '../../_models/employee-data-model';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardService } from '../../_services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  public navItemsEmp = navItemsEmp;
  public navItemsCust = navItemsCust;
  filteredNavItems = [];
  empId: string = "";
  count: number = 0;
  public empImg: string = '';
  fullName: string = "";
  base64Image: any;
  currentDate: Date;
  empData: EmployeeDataModel;
  position: string;
  displayPosition: boolean = false;
  Birth: any;
  employeeImg: string;
  ids: any;
  crmUrl: any;
  reportingManager: any;


  constructor(public authService: AuthService,
    public dashboardService: DashboardService,
    public router: Router,
    public routers: ActivatedRoute,
    private storage: StorageMap,
    private domSanitizer: DomSanitizer,
    public cd : ChangeDetectorRef
    ) {

    this.empId = localStorage.getItem("empId")
    var img = localStorage.getItem("empImg");
    var gender = localStorage.getItem("gender");
    if (img == "null" && (gender == "null" || gender == "674180000")) {
      this.empImg = "assets/img/user-icon.png";
    }
    else if (img == "null" && gender == "674180001") {
      this.empImg = "assets/img/user-icon.png";
    }
    else {
      this.empImg = "data:img/jpeg;base64," + img;

    }


    this.currentDate = new Date();
    //converting base64 img to jpeg format.
    this.base64Image = this.domSanitizer.bypassSecurityTrustUrl(this.empImg);
    this.fullName = localStorage.getItem("empName");
    this.employeeImg = localStorage.getItem("empImg");
    this.reportingManager = localStorage.getItem('reportingManager')
    
    if (this.navItems[0].url == '/ESSPortal/dashboard/') {
      this.navItems[0].url = this.navItems[0].url + this.empId;
    }

    


  }


  ngOnInit() {
    this.cd.detectChanges()
    this.displayPosition = false;
    this.getData();
    this.crmId();
    let userType = localStorage.getItem('userType');
    if(userType === 'customer' ){
      this.navItems = navItemsCust;
    }
    // else if(userType==='employee'){
    //   this.navItems = navItemsEmp;
    // }

  }

  crmId() {
    this.authService.latestCrmId.subscribe((resp) => {
      this.crmUrl = resp;
    }
    )
  }



  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  onLogout() {
    this.authService.logoutEmployee();
  }
  async getData() {debugger
    this.cd.detectChanges()
    await this.storage.get('empDetails').subscribe((empBalance: EmployeeDataModel) => {
      var NavItemsClient: any = [];
      this.empData = empBalance.userProfile;
      // this.empId = this.empData.ID;
      if (this.empData.userId == "00000000-0000-0000-0000-000000000000") {
        this.navItems.filter((i: any) => {
          if (i.name != "Leaves & Breaks" && i.name != "Dashboard" && i.name != "Daily hours") {
            NavItemsClient.push(i);
          }
        });
        this.navItems = NavItemsClient;
        this.navItemsEmp = NavItemsClient;
        this.navItemsCust =NavItemsClient;
      };
    });
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  
  }



  uploadPicture(): void {
    const fileInput = document.getElementById('uploadFile') as HTMLInputElement;
    fileInput.click();

    const preview = document.querySelector('img') as HTMLImageElement;
    const file = fileInput.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          preview.src = reader.result;
        }
      }, false);

      reader.readAsDataURL(file);

      // You can add additional logic here if needed.
    }
  }

}

