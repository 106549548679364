import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AttendanceRegisterDataModel } from '../../../_models/attendance-register-data-modal';
import { LeaveApplicationDataModel } from '../../../_models/leave-application-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { UserDataModel } from '../../../_models/user-data-model';
import { LeaveService } from '../../../_services/leave.service';
import { Sort } from '../../../_services/sort.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { date } from '../../../_nav';
import * as moment from 'moment';
import { EmployeeDataModel } from '../../../_models/employee-data-model';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  attendObj: AttendanceRegisterDataModel;
  attendanceList: AttendanceRegisterDataModel[];
  leaveObj: LeaveApplicationDataModel;
  userObj: UserDataModel;
  optionObj: OptionSetDataModel;
  optionObj2: OptionSetDataModel;
  userData: any
  month: any;
  year: any;
  claimForm: FormGroup;
  claimFormSubmitted: boolean = false;
  updateRecordId: any;
  GetAttendanceQueryRecord: any;


  constructor(private sort: Sort,
    public leaveService: LeaveService,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private fb: FormBuilder) {

    this.userObj = new UserDataModel();
    this.leaveObj = new LeaveApplicationDataModel();
    this.optionObj = new OptionSetDataModel();
    this.optionObj2 = new OptionSetDataModel();

  }

  ngOnInit(): void {

    this.attendObj = new AttendanceRegisterDataModel();
    this.spinner.show();
    this.route.paramMap.subscribe(params => {
      this.month = params.get('month');
      this.year = params.get('year');
    })
    var m = parseInt(this.month);
    this.month = m;
    var y = parseInt(this.year);
    this.year = y;
    this.getData(); // getting data from local storage api and setting it in our local variable.

    this.claimForm = this.fb.group({
      endTime: ['', Validators.required],
      Hours: ['', Validators.required],
      Reason: ['', Validators.required],
      StartTime: ['', Validators.required],
      date: [''],
      declinedReason: [''],
      status: ['']
    })

  }

  async getData() {debugger
    await this.storage.get('empDetails').subscribe((empAttendance:EmployeeDataModel) => {

      this.userData = empAttendance.userProfile;
      this.userObj.ID = this.userData.userId;
      this.attendObj.employee = this.userObj;

      //passing month in various DMs so that it can reach Attendance Register DM.
      this.optionObj.Value = this.month;
      this.leaveObj.Month = this.optionObj;
      this.attendObj.leaveDM = this.leaveObj;

      //similarly passing year.
      this.optionObj2.Value = this.year;
      this.leaveObj.Year = this.optionObj2;
      this.attendObj.leaveAppDM = this.leaveObj;

      this.postAttendanceRecords();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  /* actually here we are doing nothing other than getting data from the database but, as we can't send a DM in a 'get' request hence 'Post' is used but we'll get the attendance data only in return. */
  async postAttendanceRecords() {
    await this.leaveService.postAttendanceList(this.attendObj).subscribe((res: any) => {

      if (res.ResponseCode == 100) { // success
        this.attendanceList = res.Data;

        res.Data.forEach(element => {
          if (element.isLate === true) {
            element.isLateYN = 'Late'
          }
          else {
            element.isLateYN = 'OnTime'
          }



        });
      }
      else if (res.ResponseCode == 999) {
        this.toastr.error(res.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  //claimForm Form Control
  get claimFormControl() {
    return this.claimForm.controls;
  }

  updateToDo(position: string, record) {
    
    this.updateRecordId = record.ID
    // Extract just the date part from the provided date string
    const dateOnly = record.date.split('T')[0]; // Extracting date part only
    const timeout = record.outTime.split('T')[1].substring(0, 5); // Extracting the time part
    const timein = record.inTime.split('T')[1].substring(0, 5); // Extracting the time part
    this.claimForm.patchValue({
      endTime: timeout,
      Hours: record.totalHours,
      StartTime: timein,
      date: dateOnly,
      Reason : ''
    })

  }
  viewupdateToDo(position: string, record) {
    this.updateRecordId = record.ID
    this.spinner.show();
    this.GetAttendanceQuery();
  }

  // ###################################### API of Get Attendance Query ########################
  GetAttendanceQuery() {
    this.leaveService.GetAttendanceQuery(this.updateRecordId).subscribe((responce: any) => {
      if (responce.ResponseCode == 100) {
        this.GetAttendanceQueryRecord = responce.Data

        // Assuming `responce.Data` is the object containing the data
        const timeout = responce.Data?.SuggestedEndtTime ? moment(responce.Data?.SuggestedEndtTime).format('HH:mm') : null;
        const timein = responce.Data?.SuggestedStartTime ? moment(responce.Data?.SuggestedStartTime).format('HH:mm') : null;

        this.claimForm.patchValue({
          endTime: timeout,
          Hours: responce.Data?.ActualHour,
          StartTime: timein,
          date: responce.Data?.Dates,
          Reason: responce.Data?.Reason,
          declinedReason: responce.Data?.DeclinedReason,
          status: responce.Data?.StatusReason?.Label
        })

        this.spinner.hide();
      }
      else if (responce.ResponseCode == 999) {
        this.spinner.hide();
        // this.toastr.error(resp.message);
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      // this.toastr.error(error.message);
    });

  }

  clearForm() {
    this.claimForm.reset();
    Object.keys(this.claimForm.controls).forEach(key => {
      this.claimForm.get(key).setErrors(null);
    });
  }

  onSubmit() {
    this.claimFormSubmitted = true;

    if (this.claimForm.status == "INVALID") {
      this.spinner.hide();
      return
    }
    this.spinner.show();
    let obj1 = {
      "ID": this.updateRecordId,
      "SuggestedStartTime": this.claimForm.value.StartTime,
      "Dates": this.claimForm.value.date,
      "SuggestedEndtTime": this.claimForm.value.endTime,
      "ActualHour": this.claimForm.value.Hours,
      "Reason": this.claimForm.value.Reason
    }

    this.leaveService.postQueryForAttendance(obj1).subscribe((resp: any) => {
      if (resp.ResponseCode == 100) {
        this.toastr.success('Your request has been successfully submitted', 'Success')
        this.spinner.hide();
        location.reload();


      }
      else if (resp.ResponseCode == 999) {
        this.spinner.hide();
        // this.toastr.error(resp.message);
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      // this.toastr.error(error.message);
    });
  }

}
