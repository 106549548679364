import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { DashboardService } from '../../../_services/dashboard.service';

@Component({ 
  selector: 'app-dashboard-to-do',
  templateUrl: './dashboard-to-do.component.html',
  styleUrls: ['./dashboard-to-do.component.css']
})
export class DashboardToDoComponent implements OnInit {
  @Input() employeeId: string;
  supportReqList: Array<SupportRequestDataModel>;
  barData: any;
  doughnutData: any;
  barOptions: any;
  expectedDate: Array<any> = [];
  tSHours: Array<any> = [];
  selectedBarIndex: any;
  supportReqObj: SupportRequestDataModel;
  supportReqObj2: SupportRequestDataModel;
  doughnutFlag: boolean = false
  workLogList: Array<any>;

  constructor(public dashboardService: DashboardService,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.spinner.show();
    this.supportReqObj = new SupportRequestDataModel();
    this.supportReqObj2 = new SupportRequestDataModel();
    this.getLastWeekWorkLog();
  }

  async getLastWeekWorkLog() {
    await this.dashboardService.getWorkLogLast7Days(this.employeeId).subscribe((resWorkLog: any) => {
      if (resWorkLog.ResponseCode == 100) { //success
        this.supportReqList = resWorkLog.Data;
        this.supportReqList.sort((a, b) => {
          return <any>new Date(a.TSdate) - <any>new Date(b.TSdate);
        });
        this.getInfoForBarChart();
      }
      else if (resWorkLog.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(resWorkLog.Message, 'Error');
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getInfoForBarChart() {
    this.getExpectedResDateList();
    this.getTSHours();
    this.getBarChart();
  }

  async getExpectedResDateList() {
    await this.supportReqList?.filter((i) => {
      this.expectedDate.push(i.expectedResDate);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getTSHours() {
    await this.supportReqList?.filter((j) => {
      this.tSHours.push(j.TShours);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getBarChart() {
    this.barData = {
      labels: this.expectedDate,
      datasets: [
        {
          data: this.tSHours,
          backgroundColor: ['#cf0c2e', '#FFA900', '#39C0ED', '#00b74a', '#1266F1', '#6edbff', '#54e38e', '#4380e6']
        }
      ]
    }
    this.barOptions = {
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 2,
          }
        }],
        xAxes: [{
          ticks: {
            beginAtZero: true,
          }
        }]
      }
    };
    this.spinner.hide();
  }

  async onClickBar(e: any) {
    this.spinner.show();
    this.selectedBarIndex = e.element._index;
    await this.getIndividualBarData();
  } 

  async getIndividualBarData() {
    this.supportReqList?.filter((i, index) => {
      if (index == this.selectedBarIndex) {
        this.supportReqObj = i;
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

    this.supportReqObj.ID = this.employeeId;
    await this.getClickedDayLog();
  }

  // getting data for Individual Day Work Log.
  async getClickedDayLog() {
    await this.dashboardService.getParticularDayWorkLog(this.supportReqObj).subscribe((resParticularDay: any) => {
      if (resParticularDay.ResponseCode == 100) { //success
        this.supportReqObj2 = resParticularDay.Data;

        //used for doughnut chart data
        this.workLogList = this.supportReqObj2.toDoList;
        this.getDoughnutChart();
      }
      else if (resParticularDay.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(resParticularDay.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }

  async getDoughnutChart() {
    this.doughnutData = {
      labels: this.supportReqObj2.label,
      datasets: [
        {
          data: this.supportReqObj2.value,
          backgroundColor: ['#6edbff', '#4380e6', '#39C0ED']
        }
      ]
    }
    this.spinner.hide();
  }

  onClickDoughnut() {
    this.doughnutFlag = !this.doughnutFlag;
  }

  // navigating to the update to-do page with to-do id.
  toDoDetails(workLog: any) {
    this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + workLog.ItemId ]);
  }

  // navigating to the my-worklog page with worklog id.
  updateWorklog(workLog: any) {
    this.router.navigate(['/ESSPortal/to_dos/my_worklog/EditWork/' + workLog.ID ]);
  }
}
