import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import * as moment from 'moment';
import { stringify } from 'querystring';

@Component({
  selector: 'app-update-performance-review-form',
  templateUrl: './update-performance-review-form.component.html',
  styleUrls: ['./update-performance-review-form.component.scss']
})
export class UpdatePerformanceReviewFormComponent implements OnInit {
  allQuestionData: any
  goalsResponce: any
  fullName: string;
  subjectiveQue: any[] = [];
  objectiveQue: any[] = [];
  feedbackQue: any[] = [];

  empGoalsForm: FormGroup;
  PerformanceReviewForm: FormGroup

  RatingList: any[]
  workFormSubmitted: boolean;
  userData: EmployeeDataModel;
  // updateQue: any[] = [];
  isFormDisabled: boolean = false; // Variable to track form disable state
  responseId: any;
  PerformanceRevName: any;
  todayDate = new Date();
  minDate: string;
  maxDate: string
  secondGoalMinDate: string
  secondGoalmaxDate: string
  thirdGoalMinDate: string
  thirdGoalMaxDate: string
  description: any;
  sequenceNumber: any;
  Saveresponse: any;
  PerformanceRevId: any;
  ReviewDataLst: { PerFormanceRevStatus: any; }[];
  reviewRespId: any;
  yearlyReviewStatus: any;


  // questionType: any;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private PerformanceReviewService: PerformanceReviewService,
    private storage: StorageMap,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute

  ) {

    this.responseId = this.route.snapshot.paramMap.get('id');
    this.PerformanceReviewForm = this.fb.group({
      // Define your form controls here
      Managers: ['', Validators.required],
      Projects: ['', Validators.required],
      rating: ['', Validators.required],
      name: ['', Validators.required],
      designation: ['', Validators.required],
      goal1: ['', Validators.required],
      goal2: ['', Validators.required],
      goal3: ['', Validators.required],
      expectedOutcomes1: ['', Validators.required],
      expectedOutcomes2: ['', Validators.required],
      expectedOutcomes3: ['', Validators.required],
      achievedDate1: ['', Validators.required],
      achievedDate2: ['', Validators.required],
      achievedDate3: ['', Validators.required],
      // appQues: this.fb.array([]),
      // Add other form controls as needed


    });
    // Calculate minDate and maxDate for according to Goals of the current year
    const currentYear = this.todayDate.getFullYear();
    this.minDate = moment(`${currentYear}-04-01`).format('YYYY-MM-DD');
    this.maxDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DD');
    this.secondGoalMinDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DD');
    this.secondGoalmaxDate = moment(`${currentYear}-09-30`).format('YYYY-MM-DD');
    this.thirdGoalMinDate = moment(`${currentYear}-10-01`).format('YYYY-MM-DD');
    const nextYear = currentYear + 1;
    // Append the range from January to March of the next year
    this.thirdGoalMaxDate = moment(`${nextYear}-03-31`).format('YYYY-MM-DD');

  }
  ngOnInit(): void {

    this.spinner.show();

    this.responseId = this.route.snapshot.paramMap.get('id');
    // this.GetPerfReviewQuetions();
    this.GetPerfReviewQuetions()
    this.getDropDownRatingReviewStatus();
    this.getData();
    // this.GetPerfReviewQuetions();

  }
  patchDynamicControlValue(controlName: string, value: any) {
    if (this.PerformanceReviewForm.contains(controlName)) {
      if (value !== "null") {
        this.PerformanceReviewForm.get(controlName)?.patchValue(value);
      }
    }


  }

  async getData() {

    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myInvoice: EmployeeDataModel) => {

      this.userData = myInvoice;
      // If Contact User is logged in
      this.userData.ID = this.userData?.ID;
      this.userData.fullName = this.userData?.fullName;
      this.userData.designation = this.userData?.designation
      this.userData.reportingManager = this.userData?.reportingManager
      if (this.userData?.reportingManager?.ID) {

        this.userData.reportingManagerID = this.userData?.reportingManager?.ID
      }

      this.PerformanceReviewForm.patchValue({
        name: this.userData?.fullName,
        designation: this.userData?.designation

      })
      // this.spinner.hide();
      this.GetReviewResponseforLoggedInEmployee()
    },
    )
    // this.spinner.hide();
  }
  //Employee Form Control
  get prf() {
    return this.PerformanceReviewForm.controls;
  }

  getDropDownRatingReviewStatus() {
    // this.spinner.show();
    this.PerformanceReviewService.getDropDownRatingReviewStatus().subscribe((dropdownResponce: any) => {
      if (dropdownResponce.ResponseCode == 100) { // success
        this.RatingList = dropdownResponce.Data;
        // this.spinner.hide();
      }
      else if (dropdownResponce.ResponseCode == 999) {
        this.toastr.error(dropdownResponce.Message, 'Error');
      }
      else if (dropdownResponce.ResponseCode == 217) {
        this.toastr.error(dropdownResponce.Message, 'Error');
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    })
  }

  // ################################## API Call Show ALl Type Of Questions In Performance Review Blank Form ######  
  GetPerfReviewQuetions() {
    this.spinner.show();
    this.allQuestionData = []; // Ensure allQuestionData is initialized as an array
    this.PerformanceReviewService.GetPerfReviewQuetions(this.responseId).subscribe((questionresponce: any) => {
      if (questionresponce.ResponseCode == 100) {
        this.yearlyReviewStatus = questionresponce?.Data?.PerFormanceRevStatus?.Value
        const { PerFormanceRevStatus } = questionresponce?.Data;
        //     // Format the data into an array
        this.ReviewDataLst = [{
          PerFormanceRevStatus,
        }];
        // this.spinner.hide();
        this.PerformanceRevName = questionresponce?.Data?.PerformanceRevName
        this.description = questionresponce?.Data?.Notes
        this.PerformanceRevId = questionresponce?.Data?.PerformanceRevId

      }
    })
  }

  GetReviewResponseforLoggedInEmployee() {debugger
    
    this.PerformanceReviewService.GetReviewResponseforLoggedInEmployee(this.userData.ID, this.responseId).subscribe((questionResp: any) => {
      if (questionResp.ResponseCode == 100) { // success
        this.allQuestionData = JSON.parse(JSON.stringify(questionResp?.Data?.PerformanceRevResDetails))  
        this.reviewRespId = questionResp?.Data?.ID
        this.goalsResponce = questionResp?.Data?.goalsList

        this.PerformanceRevName = questionResp?.Data?.PerformanceRevName
        const questionsArray = questionResp.Data.PerformanceRevResDetails;

        // Ensure questionsArray is an array before proceeding
        if (Array.isArray(questionsArray)) {
          this.subjectiveQue = JSON.parse(JSON.stringify(questionsArray.filter(x => x.Category.Label === 'Subjective')));
          this.objectiveQue = JSON.parse(JSON.stringify(questionsArray.filter(x => x.Category.Label === 'Objective')));
          this.feedbackQue = JSON.parse(JSON.stringify(questionsArray.filter(x => x.Category.Label === 'Feedback')))
          questionsArray.forEach((question: any) => {
            const validators = question ? [Validators.required] : null;

            this.PerformanceReviewForm.addControl(
              question.ID,
              this.fb.control([], validators)
            );

            if (question.RatingEnabled) {
              const defaultValue = question.Rating ? question.Rating : null;
              this.PerformanceReviewForm.addControl(
                question.ID + 'ratting',
                this.fb.control([], validators)
              );

            }
          });
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }

        const data = questionResp?.Data; // Ensure Data object exists
        if (data) {
          this.PerformanceReviewForm.patchValue({
            Managers: data.Managers,
            Projects: data.Projects,
            rating: data.OverallSelfRating?.Value || null,
          });

          if (data.goalsList) {          

            data.goalsList.forEach(element => {
              if (element.GoalSequence == 1) {
                this.PerformanceReviewForm.patchValue({
                  goal1: element?.Goal,
                  expectedOutcomes1: element?.ExpOutcome,
                  achievedDate1: element?.AchievebyDate ? new Date(element.AchievebyDate).toISOString().split('T')[0] : null,
                });
              }
              else if (element.GoalSequence == 2) {
                this.PerformanceReviewForm.patchValue({
                  goal2: element?.Goal,
                  expectedOutcomes2: element?.ExpOutcome,
                  achievedDate2: element?.AchievebyDate ? new Date(element.AchievebyDate).toISOString().split('T')[0] : null,
                });
              }
              else if (element.GoalSequence == 3) {
                this.PerformanceReviewForm.patchValue({
                  goal3: element.Goal,
                  expectedOutcomes3: element.ExpOutcome,
                  achievedDate3: element.AchievebyDate ? new Date(element.AchievebyDate).toISOString().split('T')[0] : null,
                });
              }
            });
          }
          // Sort the PerformanceRevResDetails array based on the "Sequence" property
          questionResp.Data.PerformanceRevResDetails.sort((a, b) => a.Sequence - b.Sequence);

          questionResp.Data.PerformanceRevResDetails.forEach(element => {debugger
            if(element.Category.Label !== "Feedback"){
            this.patchDynamicControlValue(element.ID, element.Answer);
            }
            if(element.Category.Label == "Feedback"){
              this.patchDynamicControlValue(element.ID, element.FeedbackResponse);
              }
          });

          questionResp?.Data?.PerformanceRevResDetails?.forEach(element => {
            if (element.RatingEnabled = true) {
              this.patchDynamicControlValue1(element.ID + 'ratting', element.Rating?.Value);
            }
          });
          // Check if all fields are filled to disable the form
          this.checkIfFormIsFilled();
          // Disable all fields if the form is valid

            if ((this.yearlyReviewStatus == 674180001 || questionResp?.Data?.Status?.Value == 674180002 || questionResp?.Data?.Status?.Value == 674180003 || questionResp?.Data?.Status?.Value == 674180004 || questionResp?.Data?.Status?.Value == 674180005)) {
              this.PerformanceReviewForm.disable();
            }
        }
      }
      else if (questionResp.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(questionResp.Message, 'Error');
      }
      else if (questionResp.ResponseCode == 217) {
        this.spinner.hide();
        this.GetPerfReviewQuetions()
        // this.toastr.error(questionResp.Message, 'Error');
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }
  patchDynamicControlValue1(controlName: string, value: any) {
    if (this.PerformanceReviewForm.contains(controlName)) {
      if (value !== "null") {
        this.PerformanceReviewForm.get(controlName)?.patchValue(value);
      }
    }
  }
  checkIfFormIsFilled() {
    // Get all form control values
    const formValues = this.PerformanceReviewForm.value;
    // Check if all values are not null or empty string
    const isFormFilled = Object.values(formValues).every(value => value !== null && value !== '');
    // Update form disabled state accordingly
    this.isFormDisabled = isFormFilled;
    this.spinner.hide();
  }

  //Worklog Form Control
  get wlf() {
    this.spinner.hide();
    return this.PerformanceReviewForm.controls;
  }

  submit() {
    this.spinner.show();
    this.workFormSubmitted = true;
    if (this.PerformanceReviewForm.valid) {
      let performanceRevResDetails1 = [];
      let goalsList1 = [];
      let keyArray = Object.keys(this.PerformanceReviewForm.value);



      // Iterate through goalsApiResponse and update goalsList in object
      for (let i = 0; i < 3; i++) {
        let goalKey = 'goal' + (i + 1);
        let outcomeKey = 'expectedOutcomes' + (i + 1);
        let achievedDateKey = 'achievedDate' + (i + 1);
        let goal = this.PerformanceReviewForm.value[goalKey];
        let outcome = this.PerformanceReviewForm.value[outcomeKey];
        let achievedDate = this.PerformanceReviewForm.value[achievedDateKey];

        // Check if GoalSequence is returned from API
        if (this.goalsResponce && this.goalsResponce[i]?.GoalSequence) {
          this.sequenceNumber = this.goalsResponce[i].GoalSequence;
        }

        // Only add to goalsList if goal is not empty
        if (goal?.trim() !== '') {

          goalsList1.push({
            // ID: this.goalsResponce[i].ID, // Keep the existing ID if available
            ID: this.goalsResponce && this.goalsResponce[i]?.ID ? this.goalsResponce[i].ID : null,
            Goal: goal,
            GoalSequence: this.sequenceNumber, // Set the sequence number here
            ExpOutcome: outcome,
            AchievebyDate: achievedDate,
            EmployeeId: this.userData.ID
          });

        }
      }

      keyArray.forEach((key: string) => {
        let exist = this.allQuestionData.find((data: any) => data.ID === key);
        if (exist) {
          let controlValue = this.PerformanceReviewForm.get(key)?.value;
          // Ensure controlValue is a string
          if (controlValue == null) {
            controlValue = ''; // Set it to an empty string
          } else {
            // Ensure controlValue is a string
            if (typeof controlValue !== 'string') {
              // If controlValue is not a string, convert it to a string
              controlValue = String(controlValue);
            }
          }
          let controlRatingValue = key + 'ratting'; // Corrected typo from 'ratting' to 'rating'

          let ratingValue = String(this.PerformanceReviewForm.value[controlRatingValue]);
          // Ensure ratingValue is a string
          if (typeof ratingValue !== 'string') {
            // If ratingValue is not a string, convert it to a string
            ratingValue = String(ratingValue);
          }
          if(exist.Category.Label !== "Feedback"){
          let detail = {
            ID: exist.ID,
            Question: exist.Question,
            Answer: controlValue,
            RatingEnabled: exist.RatingEnabled,
            Sequence: exist.Sequence,
            Category: {
              Value: exist.Category.Value.toString() // Convert to string using toString() method
            },

            Rating: {
              Value: ratingValue
            },
            LinkedReviewFY: {
              ID: exist.LinkedReviewFY.ID
            }
          };

          performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
        }
          // No need to construct the final object inside the loop
          if(exist.Category.Label == "Feedback"){
            let detail = {
              ID: exist.ID,
              Question: exist.Question,
              FeedbackResponse: controlValue,
              RatingEnabled: exist.RatingEnabled,
              Sequence: exist.Sequence,
              Category: {
                Value: exist.Category.Value.toString() // Convert to string using toString() method
              },
    
              Rating: {
                Value: ratingValue
              },
              LinkedReviewFY: {
                ID: exist.LinkedReviewFY.ID
              }
            };
            performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
          }
        }
      });
      let object: any = {  // Initialize object outside the loop
        ID: this.reviewRespId,
        EmployeeId: this.userData.ID,
        Managers: this.PerformanceReviewForm.value.Managers,
        ReportingManId: this.userData?.reportingManager?.ID,
        Projects: this.PerformanceReviewForm.value.Projects,
        LinkedReviewFY: this.PerformanceRevId,
        OverallSelfRating: {
          Value: this.PerformanceReviewForm.value.rating
        },
        Status: {
          Value: "674180002"
        },
        PerformanceRevResDetails: performanceRevResDetails1, // Initialize PerformanceRevResDetails here
        goalsList: goalsList1 // Initialize goalsList here // Assuming there's no logic for goalsList here
      };
      // Send object to API after loop
      this.PerformanceReviewService.SavePerfReviewResponse(object).subscribe({
        next: (response: any) => {
          if (response.ResponseCode == 100) {
            this.Saveresponse = response.Data
            this.toastr.success('Your Performance Review record is Submitted successfully and will now proceed for Manager and HR Review.', 'Success', {
              positionClass: 'toast-top-full-width',
              timeOut: 15000 // Set to 15000 milliseconds for 15 seconds
            });
            this.router.navigate(['/ESSPortal/performance-reviews-list']);
            this.spinner.hide();
          }
          else if (response.ResponseCode == 999) {
            this.toastr.error(response.Message, 'Error');
            this.spinner.hide();
          }
        },
        error: (error: any) => {
          console.error('Error:', error);
          this.toastr.error(error, 'Error');
          this.spinner.hide();
        }
      });
    } else {
      // Form is invalid, display error messages
      this.validateAllFormFields(this.PerformanceReviewForm);
      this.spinner.hide();
      this.toastr.error('All fields are mandatory', 'Error');
    }




  }

  save() {
    this.spinner.show();

    this.workFormSubmitted = false;
    let performanceRevResDetails1 = [];
    let goalsList1 = [];
    let keyArray = Object.keys(this.PerformanceReviewForm.value);
    // Iterate through goalsApiResponse and update goalsList in object
    for (let i = 0; i < 3; i++) {
      let goalKey = 'goal' + (i + 1);
      let outcomeKey = 'expectedOutcomes' + (i + 1);
      let achievedDateKey = 'achievedDate' + (i + 1);
      let goal = this.PerformanceReviewForm.value[goalKey];
      let outcome = this.PerformanceReviewForm.value[outcomeKey];
      let achievedDate = this.PerformanceReviewForm.value[achievedDateKey];

      // Check if GoalSequence is returned from API
      if (this.goalsResponce && this.goalsResponce[i]?.GoalSequence) {
        this.sequenceNumber = this.goalsResponce[i].GoalSequence;
      }

      // Only add to goalsList if goal is not empty
      if (goal?.trim() !== '') {

        goalsList1.push({
          // ID: this.goalsResponce[i].ID, // Keep the existing ID if available
          ID: this.goalsResponce && this.goalsResponce[i]?.ID ? this.goalsResponce[i].ID : null,
          Goal: goal,
          GoalSequence: this.sequenceNumber, // Set the sequence number here
          ExpOutcome: outcome,
          AchievebyDate: achievedDate,
          EmployeeId: this.userData.ID
        });

      }
    }




    keyArray.forEach((key: string) => {

      let exist = this.allQuestionData.find((data: any) => data.ID === key);
      if (exist) {
        let controlValue = this.PerformanceReviewForm.get(key)?.value;
        // Ensure controlValue is a string
        if (controlValue == null) {
          controlValue = ''; // Set it to an empty string
        } else {
          // Ensure controlValue is a string
          if (typeof controlValue !== 'string') {
            // If controlValue is not a string, convert it to a string
            controlValue = String(controlValue);
          }
        }
        let controlRatingValue = key + 'ratting'; // Corrected typo from 'ratting' to 'rating'

        let ratingValue = String(this.PerformanceReviewForm.value[controlRatingValue]);
        // Ensure ratingValue is a string
        if (typeof ratingValue !== 'string') {
          // If ratingValue is not a string, convert it to a string
          ratingValue = String(ratingValue);
        }
        if(exist.Category.Label !== "Feedback"){
        let detail = {
          ID: exist.ID,
          Question: exist.Question,
          Answer: controlValue,
          RatingEnabled: exist.RatingEnabled,
          Sequence: exist.Sequence,
          Category: {
            Value: exist.Category.Value.toString() // Convert to string using toString() method
          },

          Rating: {
            Value: ratingValue
          },
          LinkedReviewFY: {
            ID: exist.LinkedReviewFY.ID
          }
        };

        performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
}
        // No need to construct the final object inside the loop

        if(exist.Category.Label == "Feedback"){
          let detail = {
            ID: exist.ID,
            Question: exist.Question,
            FeedbackResponse: controlValue,
            RatingEnabled: exist.RatingEnabled,
            Sequence: exist.Sequence,
            Category: {
              Value: exist.Category.Value.toString() // Convert to string using toString() method
            },
  
            Rating: {
              Value: ratingValue
            },
            LinkedReviewFY: {
              ID: exist.LinkedReviewFY.ID
            }
          };
          performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
        }
      }
      // else {
      //   this.spinner.hide()
      // }
    });


    let object: any = {
      ID: this.reviewRespId,
      EmployeeId: this.userData.ID,
      Managers: this.PerformanceReviewForm.value.Managers,
      ReportingManId: null,
      Projects: this.PerformanceReviewForm.value.Projects,
      LinkedReviewFY: this.PerformanceRevId,
      OverallSelfRating: {
        Value: this.PerformanceReviewForm.value.rating
      },
      Status: {
        Value: "1"
      },
      PerformanceRevResDetails: performanceRevResDetails1,
      goalsList: goalsList1 // Initialize goalsList here
    };
    // Send object to API after loop
    this.PerformanceReviewService.SavePerfReviewResponse(object).subscribe({
      next: (response: any) => {
        if (response.ResponseCode == 100) {
          this.toastr.success('Your Performance Review record is saved as DRAFT and no action will be taken till you complete it and SUBMIT the form. Please complete it ASAP. ', 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 15000 // Set to 15000 milliseconds for 15 seconds
          });
          this.Saveresponse = response.Data
          this.router.navigate(['/ESSPortal/performance-reviews-list']);
          this.spinner.hide();

        }
        else if (response.ResponseCode == 999) {
          this.toastr.error(response.Message, 'Error');
          this.spinner.hide();
        }
        else {
          this.toastr.error(response.Message, 'Error');
          this.spinner.hide();
        }
      },
      error: (error: any) => {
        console.error('Error:', error);
        this.toastr.error(error.Message, 'Error');
        this.spinner.hide();
      }
    });
  }
  // Function to validate all form fields
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
    this.spinner.hide();
  }

  modelValidate() {
    this.workFormSubmitted = true
    if (this.PerformanceReviewForm.invalid) {

      // Form is invalid, display error messages
      this.validateAllFormFields(this.PerformanceReviewForm);
      this.spinner.hide();
      this.toastr.error('All fields are mandatory', 'Error');
    }
  }
}