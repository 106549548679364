import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiURL: string = "";

  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;
  }

  ngOnInit() {

  }

  // used for dashboard pie chart
  getTimeLoggedThisWeek(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/GetTimeLoggedThisWeek/' + id);
  }

  // used for dashboard pie chart
  getMinTimeLoggedThisWeek(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeAttendance/GetEmployeeCurrentWeekMinTimeLog/' + id);
  }

  // used for dashboard pie chart
  getTimeLoggedLastWeek(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/GetTimeLoggedLastWeek/' + id);
  }

  // used for dashboard pie chart
  getMinTimeLoggedLastWeek(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeAttendance/GetEmployeeLastWeekMinTimeLog/' + id);
  }

  // used for dashboard pie chart
  getBalanceLeaveDashboard(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/GetBalanceLeaveDashboard/' + id);
  }

  getLeavesGraphData(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/GetLeavesCountForDashboardGraph/' + id);
  }

  getSoluzioneHolidays(): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/GetSoluzioneHolidaysDashboard/');
  }

  getUpcomingEmployeeBirthdays(): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/GetSoluzioneUpcomingBirthdays/');
  }

  getWorkLogLast7Days(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/WorkLogLast7Days/' + id);
  }

  // getting that particular day log by posting that day's data.
  getParticularDayWorkLog(supportReqModel: any) {
    return this.HttpClient.post(this.apiURL + 'Dashboard/ToDosOnWorkLogDate', supportReqModel);
  }

  // used for getting to do calendar data.
  getToDoCalendar(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'Dashboard/ToDoCallendar/' + id);
  }

  //Get Soluzione Upcoming Event dashboard activity days 
  getActivityDays(): Observable<any> {
    return this.HttpClient.get(this.apiURL +'Dashboard/GetSoluzioneUpcomingEvent/');

  }

  getEmployeeOfTheMonth(): Observable<any> {
    return this.HttpClient.get(this.apiURL +'Dashboard/GetSoluzioneAwards/');

  }

  //get employee technical skills 
  getEmployeeTechSkills(id : any): Observable<any> {
    return this.HttpClient.get(this.apiURL +'Dashboard/GetEmployeeSkills?empID=' + id);

  }

  getAwardsImages(id:any): Observable<any>{
    return this.HttpClient.get(this.apiURL+'Dashboard/GetSoluzioneAwardsImageByAwardsId/?awardId='+id)
  }

}
