import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from '../../../../app//app.config';  
@Component({
  selector: 'app-dashboard-banner',
  templateUrl: './dashboard-banner.component.html',
  styleUrls: ['./dashboard-banner.component.scss']
})
export class DashboardBannerComponent implements OnInit {
  events: Array<{ title: string, description: string }> = [];
  @Input() hasEvent: boolean = false;
  @Input() eventImage: string = '';
  showBanner: boolean = AppConfig.showBanner; 
  constructor() { }

  ngOnInit(): void {
    this.fetchEvents();
  }

  fetchEvents(): void {
    // Replace this with your API call or service to fetch events
    // For example:
  
  }
}
