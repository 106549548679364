import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../_models/employee-data-model';
import { AuthService } from '../../_services/auth.service';
import { SupportRequestService } from '../../_services/support-request.service';
import { ToDosService } from '../../_services/to-dos.service';
import { AppConfig } from '../../../app/app.config';  

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})

export class DashboardComponent implements OnInit {
  empDetails: EmployeeDataModel;
  alerts: Array<any> = [];
  empId: string = "";

  showBanner: boolean = AppConfig.showBanner;  // Initialize using config setting
  hasEvent: boolean = AppConfig.hasEvent;  // Initialize using config setting
  eventImage: string = AppConfig.eventImage;

  constructor(
    public HttpClient: HttpClient,
    public authService: AuthService,
    private storage: StorageMap,
    private router: Router,
    // public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    public toDosService: ToDosService,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
       // Logic to check if there is an event, celebration, or holiday
       this.checkForEvents();

    this.route.params.subscribe((params: any) => {

      this.empId = params.empId;
    });
    this.spinner.show();
    this.getSolzStatusOptionSet()
  }

  async getCalendarData(calendarData) {
    this.alerts = calendarData; // used for making alerts closable.
    this.spinner.hide();
  }

  async updateTodo(toDoId: any) {
    this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + toDoId]);
  }

  close(alert: any) {
    this.alerts.splice(this.alerts.indexOf(alert), 1)
  }

  // Here we  optimization of API'S set Data in Cache And Get Whare We Need in this Work Our Loading Time Fast
  //Work Status
  getSolzStatusOptionSet() {
    this.supportReqService.getDropDownData("solzStatus").subscribe((solzStatusResp: any) => {
      if (solzStatusResp.ResponseCode == 100) {
        // this.toDosService.getWorkStatusOptionSet(solzStatusResp.Data)
        localStorage.setItem("setWorkStatus", JSON.stringify(solzStatusResp.Data));
        this.spinner.hide();
        this.getTestingEnvOptionSet()
      }
      else {
        this.spinner.hide();
      }
    })
  }

  //Testing Environment
  async getTestingEnvOptionSet() {
    await this.supportReqService.getDropDownData("testingenvironment").subscribe((testRes: any) => {

      if (testRes.ResponseCode == 100) {
        localStorage.setItem("setEnvironment", JSON.stringify(testRes.Data));
        this.spinner.hide();
        this.getUserPriorityOptionSet()

      }
    });
  }
  //User Priority
  async getUserPriorityOptionSet() {
    await this.supportReqService.getDropDownData("userpriority").subscribe((priorityRes: any) => {
      if (priorityRes.ResponseCode == 100) {
        // this.userPriorityList = priorityRes.Data;
        // this.getTestingEnvOptionSet();
        localStorage.setItem("setPriority", JSON.stringify(priorityRes.Data));
        this.spinner.hide();
        this.getEmployeeProjects()
      }
    });
  }

  //Employee Project 
  async getEmployeeProjects() {
    await this.toDosService.getProjectsByCustomerId(this.empId).subscribe((empProjects: any) => {
      this.spinner.hide();
      if (empProjects.ResponseCode == 100) { // success
        localStorage.setItem("setempProjects", JSON.stringify(empProjects.Data));
        this.spinner.hide();
        // this.allProjectList = empProjects.Data;
      }
    });
  }






  checkForEvents(): void {
    // Example logic (replace with your actual logic)
    const today = new Date();
    // Assuming you have a service that provides event information
    const event = this.getEventForToday(today);

    if (event) {
      this.hasEvent = true;
      this.eventImage = event.imageUrl; // URL of the event banner image
      this.showBanner = true;
    } else {
      this.showBanner = false;
    }
  }

  getEventForToday(date: Date): any {
    // Replace this with your actual event-checking logic
    return {
      imageUrl: this.eventImage
    };
  }
}
