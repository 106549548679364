import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../_services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-employee-of-month',
  templateUrl: './dashboard-employee-of-month.component.html',
  styleUrls: ['./dashboard-employee-of-month.component.scss']
})
export class DashboardEmployeeOfMonthComponent implements OnInit {
  alertFlag: boolean = false;
  alerts: any = [
    {
      type: 'info',
      msg: `No awards to display`
    },
  ]
  awordList: any;
  position: any = [];
  displayPosition: boolean;
  openModeldiscraption: any;
  employeeImage: any;
  empName: any;
  designation: any;
  imageUrl: string;
  imagesAward = [];
  responsiveOptions: any[];
  awardImgData: any;
  showModal = false;

  constructor(
    public dashboardService: DashboardService,
    public toastr: ToastrService,
    private cd: ChangeDetectorRef,

    private spinner: NgxSpinnerService
  ) {
    this.getEmployeeOfTheMonth()
  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];

  }
  async getEmployeeOfTheMonth() {
    this.dashboardService.getEmployeeOfTheMonth().subscribe((birthdays: any) => {
      if (birthdays.ResponseCode == 100) { //success
        this.alertFlag = false;
        this.awordList = birthdays.Data;

      }
      else if (birthdays.ResponseCode == 217) {
        this.alertFlag = true;
      }
      else if (birthdays.ResponseCode == 999) {

        this.toastr.error(birthdays.Message, 'Error');
      }

    }, error => {
      this.toastr.error(error.message);
    });
  }

  async getAwardsImagesid(item) {

    this.dashboardService.getAwardsImages(item).subscribe((imgresp: any) => {
      if (imgresp.ResponseCode == 100) {
        this.awardImgData = imgresp.Data;
        this.openModeldiscraption = imgresp.Data[0].description;
        this.imagesAward = imgresp.Data[0].awardImages;
        this.awardImgData.forEach(element => {

          if (element.id === item) {
            this.openModeldiscraption = element.description;
            this.employeeImage = element.employeeImg;
            this.empName = element.employee.Name;
            this.designation = element.designation;
            // element.awardImages.forEach(ele => { 
            //   this.imagesAward.push(ele)
            // });

          }
        });

      }
      this.spinner.hide();
    })
  }

  test() {
    this.openModeldiscraption = null;
    this.awardImgData = null;
    this.employeeImage = null;
    this.empName = null;
    this.designation = null;
    this.awardImgData = [];
    this.imagesAward = [];
  }

  showPositionDialog(position: string, item) {
    this.position = [];
    this.spinner.show();
    this.getAwardsImagesid(item)
    this.position = position;
    this.displayPosition = true;
    // this.imagesAward = [];
    // this.awordList.forEach(element => {
    //   if (element.id === item) {
    //     this.openModeldiscraption = element.description;
    //     this.employeeImage = element.employeeImg;
    //     element.awardImages.forEach(ele => {
    //       this.imagesAward.push(ele)
    //     });
    //     this.empName = element.employee.Name;
    //     this.designation= element.designation;  
    //   }
    // }); 
    this.cd.detectChanges()
  }
}
