import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {
  apiURL: string = "";
  public leaveModel: any;

  private leaveObj = new BehaviorSubject<any>(null);
  latestLeaveObj = this.leaveObj.asObservable();
  //public leaveMonth: number;
  //public leaveYear: number;

  constructor(public HttpClient: HttpClient) {
    this.apiURL = environment.apiURL;

  }

  updateLeaveRecord(leaveObj: any) {
    this.leaveObj.next(leaveObj);
  }

  // retreiving applied leaves list.
  getAppliedLeavesList(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeLeaveRecords/AppliedLeaveRecordList/' + id);
  }

  // retreiving accepted leaves list.
  getAcceptedLeavesList(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeLeaveRecords/AcceptedLeaveRecordList/' + id);
  }

  // applying for new leave.
  postNewRequest(leaveModel: any) {
    return this.HttpClient.post(this.apiURL + 'EmployeeLeaveRecords/NewLeaveRequest', leaveModel);
  }

  // canceling a leave request.
  postCancelLeave(leaveRecord: any) {
    return this.HttpClient.post(this.apiURL + 'EmployeeLeaveRecords/CancelLeave', leaveRecord);
  }

  // retrieve leave balance.
  getLeaveBalanceList(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeLeaveRecords/LeaveBalanceRecordList/' + id);
  }

  // retrieve detail of a particular leave.
  getLeaveBalanceDetails(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeLeaveRecords/LeaveBalanceMonthlyRecordList/' + id);
  }

  // posting attendance dm and in return retreiving the list of a particular month and year.
  postAttendanceList(attendanceModel: any) {
    return this.HttpClient.post(this.apiURL + 'EmployeeAttendance/EmployeeAttendanceList', attendanceModel);
  }

  // retrieving break list.
  getBreakList(breakModel: any): Observable<any> {
    //here although we are retrieving data but still we do that by post method.
    return this.HttpClient.post(this.apiURL + 'ToDos/GetEmloyeeBreaKLogsByEmployeeId', breakModel);
  }

  // posting a new break.
  postNewBreak(breakModel: any) {
    return this.HttpClient.post(this.apiURL + 'ToDos/SaveEmployeeBreakLog', breakModel);
  }

  postQueryForAttendance(obj){
    return this.HttpClient.post(this.apiURL + 'EmployeeAttendance/AskQueryForAttendance' , obj)
  }

  GetAttendanceQuery(id){

    return this.HttpClient.get(this.apiURL + 'EmployeeAttendance/GetAttendanceQuery?id=' + id)
  }
}