import { Component, OnInit } from '@angular/core';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageMap } from '@ngx-pwa/local-storage';
import { EmployeeDataModel } from '../../../_models/employee-data-model';

@Component({
  selector: 'app-performance-reviews-list',
  templateUrl: './performance-reviews-list.component.html',
  styleUrls: ['./performance-reviews-list.component.scss']
})
export class PerformanceReviewsListComponent implements OnInit {
  ReviewDataLst: any[] = [];
  userData: any;
  empId: any;
  ReviewDataList: any;
  PerformanceRevId: any;
  reviewRespListId: any;

  constructor(private PerformanceReviewService: PerformanceReviewService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private storage: StorageMap,) { }

  ngOnInit(): void {
    this.spinner.show();
    // this.GetPerfReviewQuetions()
    this.getData();
   

  }

  async getData() {
    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myInvoice: EmployeeDataModel) => {

      this.userData = myInvoice.userProfile;
      // If Contact User is logged in
      this.empId = this.userData?.userId;
      // this.spinner.hide();
      this.GetAllPerformanceReviewList()
      // this.GetReviewResponseforLoggedInEmployee()
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  GetReviewResponseforLoggedInEmployee() {
    this.spinner.show();
    this.PerformanceReviewService.GetReviewResponseforLoggedInEmployee(this.empId, this.PerformanceRevId).subscribe((ResponseList: any) => {
      if (ResponseList.ResponseCode == 100) {
        //if record present then redirect to update form
        this.router.navigate(['/ESSPortal/update-performance-review-form/' + this.PerformanceRevId]);
        // this.ReviewDataLst = ResponseList.Data;
        const { PerformanceRevName, OverallSelfRating, Managers, Projects, ID } = ResponseList?.Data;
        // Format the data into an array
        this.ReviewDataLst = [{
          PerformanceRevName,
          OverallSelfRating,
          Managers,
          Projects,
          ID
        }];
        this.spinner.hide();
      }
      else if (ResponseList.ResponseCode == 999) {
        this.toastr.error(ResponseList.Message, 'Error');
        this.spinner.hide();
      }
      else if (ResponseList.ResponseCode == 217) {
        // this.toastr.error(ResponseList.Message, 'Error');
        this.spinner.hide();
        //no record found then show blank form
        this.router.navigate(['/ESSPortal/performance-reviews-form/' + this.PerformanceRevId]);
      }
      else {
        this.spinner.hide();
      }
    },
      error => {
        this.spinner.hide();
        this.toastr.error(error.message);
      });
  }

  // TypeScript code
  GetAllPerformanceReviewList() {
    this.PerformanceReviewService.GetAllPerformanceReviewList(this.empId).subscribe((ReviewresponceLst: any) => {
      if (ReviewresponceLst?.Data && ReviewresponceLst.Data.length > 0) {
         this.ReviewDataList = ReviewresponceLst.Data
      }
      else  if (ReviewresponceLst.Message) {
        this.spinner.hide();
      }
      else  if (ReviewresponceLst?.Data.Message == 217) {
        this.spinner.hide();
      }
      this.spinner.hide();
    });
  }

  // sending to-do id to my worklog page.
  performanceDetails(record: any) {
    this.PerformanceRevId = record.PerformanceRevId
    this.reviewRespListId = record.ID
    this.GetReviewResponseforLoggedInEmployee()
    // this.router.navigate(['/ESSPortal/update-performance-review-form/' + id]);
  }


}
