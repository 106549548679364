import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { EmployeeRecordService } from '../../_services/employee-record.service';

@Component({
  selector: 'app-my-hiring-application',
  templateUrl: './my-hiring-application.component.html',
  styleUrls: ['./my-hiring-application.component.scss']
})
export class MyHiringApplicationComponent implements OnInit {
  CandidateApplication: any;
  employeId: string;

  constructor(
    public toastr: ToastrService,
    private spinner : NgxSpinnerService,
    private employeeRecordService: EmployeeRecordService,

  ) { }

  ngOnInit(): void {
    this.spinner.show();
    let empId = localStorage.getItem('empId');
    this.employeId = empId
    this.getCandidateApplicationByEmployeeId(this.employeId)
  }
  getCandidateApplicationByEmployeeId(employeId) {
    this.employeeRecordService.getCandidateApplicationByEmployeeId(employeId).subscribe((Responce) => {
      if (Responce.ResponseCode == 100) {
        this.CandidateApplication = Responce.Data;
        this.spinner.hide();
      }
      else if (Responce.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(Responce.Message, 'Error');
      }
      else if (Responce.ResponseCode == 217) {
        this.spinner.hide();
        // this.toastr.error(Responce.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    })

  }
}
