import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';

@Component({
  selector: 'app-todos-bugs',
  templateUrl: './todos-bugs.component.html',
  styleUrls: ['./todos-bugs.component.scss']
})
export class TodosBugsComponent implements OnInit {
  supportReqObj: SupportRequestDataModel;
  empData: EmployeeDataModel;
  itemTypeList: Array<OptionSetDataModel> = [];
  userPriorityList: Array<OptionSetDataModel> = [];
  testingEnvironmentList: Array<OptionSetDataModel> = [];
  allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  empToDosObj: ToDosDataModal;
  solzStatusList: Array<OptionSetDataModel> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  typeOfItemList1: any;
  allEmployeeList: any;
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList: any;
  linkedTaskFlag: boolean = false;
  id: number;
  projectName: any;
  userPriority: string;
  sprintName: string;
  isBug: string;
  updateToDoprojectName: any;
  updateToDouserPriority: any;
  updateToDoisBug: any;
  parentId: any;
  sprintId: any;
  updateToDolinkedTaskName: any;
  updateToDouserStory: string;
  isBugTodos: any;
  linkedTaskNames: any;
  updateToDoisBugRecordId: string;
  todoBugsList: any;
  bugListData: any;
  updateToId: any;
  updateToIds: any;
  assigneeId: string;
  assignedReviewerId: string;
  itemNo: string;
  updateToDoAssigneedId: string;
  updateToDoReviewerdId: string;
  updateToDoItemnoSrdId: string;
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  testingEnvironment: string;
  updateToDotestingEnvironment: string;


  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRouter: ActivatedRoute,) { }

  ngOnInit(): void {
    this.spinner.show();
     
    // this.getProjectSprints();
    this.supportReqForm = this.fb.group({
      ID: [''],
      Name: [''],
      createdon: [''],
      expectedResDate: [null],
      createdOn: [null],
      ItemId: [''],
      itemNo: [''],
      title: ['', [Validators.required]],
      projectName: [''],
      projectId: [null, [Validators.required]],
      solzStatus: ['',],
      itemDescription: ['', [Validators.required]],
      reproSteps: [''],
      parentId: [null],
      parentItemNo: [''],
      parentTitle: [''],
      linkedTask: [null],
      userPriority: [null, [Validators.required]],
      typeOfItem: ["674180002",],
      workArea: [null],
      isClientReported: ["false", [Validators.required]],
      releaseNumber: [''],
      assigneeId: [null],
      assigneeName: [''],
      assignedReviewerId: [null],
      assignedReviewerName: [''],
      newEmpId: [''],
      newEmpName: [''],
      implementationeffort: [null],
      revieweffort: [null],
      comment: [''],
      createdBy: [''],
      createdByContact: [''],
      CreatedBy: [''],
      startDate: [null],
      testingEnvironment: [null],
      dateMoved: [null],
      descriptionOfResolution: [''],
      typeOfItems: ['674180002'],
      isBug: ["true", [Validators.required]],
      sprintName: [''],
      sprintId: [null],
      updateToId:[''],


    });
     
    this.updateToIds = this.activatedRouter.snapshot.paramMap.get('id')
    this.updateToDoprojectName = this.activatedRouter.snapshot.paramMap.get('projectName');
    this.updateToDouserStory = this.activatedRouter.snapshot.paramMap.get('userPriority');
    this.updateToDolinkedTaskName = this.activatedRouter.snapshot.paramMap.get('linkedTaskName');
    this.updateToDoisBugRecordId = this.activatedRouter.snapshot.paramMap.get('isEdit');
    this.updateToDoAssigneedId = this.activatedRouter.snapshot.paramMap.get('assignee');
    this.updateToDoReviewerdId = this.activatedRouter.snapshot.paramMap.get('reviewer');
    this.updateToDoItemnoSrdId = this.activatedRouter.snapshot.paramMap.get('itemnoSr');
    this.updateToDotestingEnvironment = this.activatedRouter.snapshot.paramMap.get('testingEnv');

    
    this.updateToId = this.updateToIds,
    this.projectName = this.updateToDoprojectName?.split('/')[1],
      this.parentId = this.updateToDouserStory?.split('/')[1]
    this.linkedTaskNames = this.updateToDolinkedTaskName?.split('/')[1]
    this.todoBugsList = this.updateToDoisBugRecordId?.split('/')[1]
    this.assigneeId = this.updateToDoAssigneedId?.split('/')[1]
    this.assignedReviewerId = this.updateToDoReviewerdId?.split('/')[1]
    this.itemNo = this.updateToDoItemnoSrdId?.split('/')[1]
    this.testingEnvironment =  this.updateToDotestingEnvironment?.split('/')[1] 
    this.linkedTaskNames = this.itemNo + ' '+ '|' + ' ' + this.linkedTaskNames 
    this.projectSelected(this.projectName);
    // this.GetAllToDoByIsBugTrueOrFalse(this.projectName);
    this.supportReqForm.patchValue({
      projectId: this.projectName,
      parentTitle: this.parentId,
      linkedTask: this.linkedTaskNames,
      assigneeId:this.assigneeId,
      assignedReviewerId: this.assignedReviewerId,
      itemNo:this.itemNo,
      testingEnvironment:this.testingEnvironment
    });

    this.getData(); // getting data from local storage api and setting it in our local variable.

    //get data from local storage (work status , testing environment, priority , Emp projects by emp Id)
    this.solzStatusList = JSON.parse(localStorage.getItem('setWorkStatus'));  
    this.testingEnvironmentList = JSON.parse(localStorage.getItem('setEnvironment'));  
    this.userPriorityList = JSON.parse(localStorage.getItem('setPriority'));  
    this.allProjectList = JSON.parse(localStorage.getItem('setempProjects'));  
    this.supportReqForm.patchValue({
              solzStatus: 674180000,
              userPriority: this.userPriorityList[2].Value,
              // projectId: this.allProjectList[0].ID,
            });

  }

  //*********************** Get APIs Begin *****************************

  async getData() {
    // inintialising data here so as to call this function only for updating the to do.
    this.startDateFlag = false;
    this.fieldRequiredFlag = false;
    this.reviewerEffortfieldFlag = false;
    this.supportReqFormSubmitted = false;
    this.contactLoggedIn = false;

    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {
      this.empData = empNewRequest.userProfile

      // If Soluzione Emp/Manager logged in
     if (this.empData.userId) {
        this.contactLoggedIn = false;
        this.isManager = this.empData?.isManager;
        this.empId = this.empData?.userId
        // this.getEmployeeProjects(this.empData.ID);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // Sprint Based On Project Selected
  async getProjectSprints(projectId) {
    await this.toDosService.getProjectSprints(projectId).subscribe((sprintResp: any) => {
      if (sprintResp.ResponseCode == 100) {//success
        this.sprintsList = sprintResp.Data


      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }


  //*********************** Get APIs End *****************************

  async getAllEmployeeListOnProjectId(id) {
    await this.toDosService.getAllEmployeeData(id).subscribe((allEmpRes: any) => {
      if (allEmpRes.ResponseCode == 100) {

        this.allEmployeeList = allEmpRes.Data;
      }

    })
  }
  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }

  // Assign Assignee or Assigned Reviewer as the current user according to flag value which is sent on click event.
  assignMe(val: number) {
    if (val == 0) {
      this.supportReqForm.patchValue({
        assigneeId: this.empId
      });
    }

    else {
      this.supportReqForm.patchValue({
        assignedReviewerId: this.empId
      });
    }
  }
  // Get all the Project UserStories  with project id

  async projectSelected(projectId: any) {

    this.getProjectSprints(projectId);
    this.getAllEmployeeListOnProjectId(projectId);
    // this.getAllEmployeeDetails(projectId);
    var project = { value: projectId, label: this.supportReqForm.value.isClientReported }

    await this.supportReqService.getAllProjectUserStories(projectId).subscribe((userStoryResp: any) => { 
      if (userStoryResp.ResponseCode == 100) { // success
 
        this.userStoryList = userStoryResp.Data;
        this.supportReqForm.patchValue({
          parentId: this.parentId,
        });
      }

      else if (userStoryResp.ResponseCode == 999) {
        this.toastr.error(userStoryResp.Message, 'Error');
        this.spinner.hide();

      }
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  // async GetAllToDoByIsBugTrueOrFalse(projectId) {
     

  //   await this.toDosService.GetAllToDoByProjectId(projectId).subscribe((respBug: any) => {
       

  //     if (respBug.ResponseCode == 100) {
  //       // success

  //       this.isBugTodos = respBug.Data;

  //       respBug.Data.filter(element => {
  //         if (element.ID === this.linkedTaskNames) {
  //           this.supportReqForm.patchValue({
  //             linkedTask: this.linkedTaskNames,
  //             // userPriority: this.userPriorityList[2].Value
  //           });

  //         }
  //       })
  //     }
  //     else if (respBug.ResponseCode == 999) {
  //       this.toastr.error(respBug.Message, 'Error');
  //       this.spinner.hide();

  //     }
  //     this.spinner.hide();

  //   }, error => {
  //     this.spinner.hide();
  //     this.toastr.error(error.message);
  //   });
  // }


  /*On Change of solz status i.e if solz status is  "Not Started" or "Analyzing" or "On Hold" or "Duplicate" or "QA Failed" or "Canceled" than ETA and Estimated Effort is not Required, else Bussiness Required.*/
  solzStatusSelected(val: number) {
    // If Employee Logged In.
    if (this.empData.contact == null) {
      if (val == 674180000 || val == 674180009 || val == 674180008 || val == 674180012 || val == 674180006 || val == 674180010 || val == 674180004 || val == 674180005) {
        this.fieldRequiredFlag = false;
        this.startDateFlag = false;
      }

      else {
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
      }

      //If Solz status is "Review in progress" or "Review Complete" or "Review Failed" or "Deployed" then change review effort to required or else not required
      if (val == 674180004 || val == 674180005 || val == 674180007) {
        this.reviewerEffortfieldFlag = true;
        this.startDateFlag = true;
      }

      else {
        this.reviewerEffortfieldFlag = false;
      }
    }
  }

  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);
  
    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }

  onSubmit(submitStatus) { //if submit status is 1 then save and stay same page  if  submitt status is 2 then save and redirect to previous page 
     

    this.spinner.show();
    this.supportReqFormSubmitted = true;
    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      return;
    }

    var today = new Date();
    this.supportReqForm.value.createdOn = moment(today).format('YYYY-MM-DD');

    if (this.contactLoggedIn) {
      this.supportReqForm.value.isClientReported = "true";
    }

    var st = this.supportReqForm.value.solzStatus;
    this.supportReqForm.value.solzStatus = new Object();
    this.supportReqForm.value.solzStatus.Value = st;

    var up = this.supportReqForm.value.userPriority;
    this.supportReqForm.value.userPriority = new Object();
    this.supportReqForm.value.userPriority.Value = up;

    var ti = this.supportReqForm.value.typeOfItem;
    this.supportReqForm.value.typeOfItem = new Object();
    this.supportReqForm.value.typeOfItem.Value = ti;

    //TypeofItems Field Hide In html but send value on toto
    var toi = this.supportReqForm.value.typeOfItems;
    this.supportReqForm.value.typeOfItems = new Object();
    this.supportReqForm.value.typeOfItems.value = toi;


    //Sprint Id Send 
    var spi = this.supportReqForm.value.sprintId;
    this.supportReqForm.value.sprintId = new Object();
    this.supportReqForm.value.sprintId = spi;

    var pri = this.supportReqForm.value.parentId;
    this.supportReqForm.value.parentId = new Object();
    this.supportReqForm.value.parentId = pri;


    var isr = this.supportReqForm.value.isClientReported;
    this.supportReqForm.value.isClientReported = JSON.parse(isr);

    //Is Bug  New Field isbug Yes Or No
    var ib = this.supportReqForm.value.isBug;
    this.supportReqForm.value.isBug = JSON.parse(ib);
 
    //Linked Task
    var lts = this.supportReqForm.value.updateToId;
    this.supportReqForm.value.updateToId = new Object();
    this.supportReqForm.value.linkedTask = this.updateToId;

    this.supportReqForm.value.workArea = new Object();
    this.supportReqForm.value.workArea.Value = 100000000; // setting work area to Hourly Task permanently.

    if (this.supportReqForm.value.testingEnvironment != null) {
      var te = this.supportReqForm.value.testingEnvironment;
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = te;
    }

    else {
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = 674180004
    }

    if (this.supportReqForm.value.startDate != null) {
      var date = new Date(this.supportReqForm.value.startDate);
      var sd = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.startDate = sd;
    }

    if (this.supportReqForm.value.expectedResDate != null) {
      var erd = this.supportReqForm.value.expectedResDate;
      this.supportReqForm.value.expectedResDate = moment(erd).format('YYYY-MM-DD');
    }

    if (!this.contactLoggedIn) {
      this.supportReqForm.value.createdBy = this.empId;
      this.supportReqForm.value.CreatedBy = this.empId;
    }

    this.supportReqService.postNewToDo(this.supportReqForm.value).subscribe((newRes: any) => {
      if (newRes.ResponseCode == 100) {// success
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = newRes.Data;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }

        else {  // else just show message.
          this.spinner.hide();
          this.toastr.success(newRes.Message, 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          }); 
          if(submitStatus===2){ 

            this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.updateToIds]);
          }
        }
      }

      else if (newRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(newRes.Message);
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }

  // async uploadFiles(attachFiles: any) {
  //   await this.supportReqService.postUploadFilesWithToDo(attachFiles).subscribe((upRes: any) => {
  //     if (upRes.ResponseCode == 100) { // success
  //       this.toastr.success(upRes.Message, 'Success', {
  //         positionClass: 'toast-top-full-width',
  //         timeOut: 5000
  //       });
  //       this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.upLoadFileList[0]?.ID]);
  //     }

  //     else if (upRes.ResponseCode == 999) {
  //       this.spinner.hide();
  //       this.toastr.error(upRes.Message);
  //     }

  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     this.toastr.error(error.message);
  //   });
  // }
  
  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {
    await this.supportReqService.postUploadFilesWithToDos(attachFiles).subscribe((upRes: any) => {
      if (upRes.ResponseCode == 100) { // success
        this.toastr.success(upRes.Message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        this.router.navigate(['/ESSPortal/to_dos/my_to_dos']);
      }

      else if (upRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(upRes.Message);
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  expandLabel(selected: any) {

    if (selected == "true") {
      this.linkedTaskFlag = true;
    }
    else {
      this.linkedTaskFlag = false;
    }
  }
  GetTodosByLinkedTaskIdOnBug(linkedTaskAddId) {
     
    this.supportReqService.GetTodosByLinkedTaskIdOnBug(linkedTaskAddId).subscribe((bugResp: any) => {
       
      if (bugResp.ResponseCode == 100) {
        // this.getAllEmployeeListOnProjectId(projectId);
          // success
        this.supportReqForm.disable();
        this.bugListData = bugResp.Data;
        let editData = this.bugListData.filter(ele => ele.ID === this.todoBugsList)
        this.supportReqForm.patchValue({
          // ID: editData[0].ID,
          title: editData[0].title,
          ItemId: editData[0].ItemId,
          Name: editData[0].Name,
          assignedReviewerId: editData[0].assignedReviewerId,
          assignedReviewerName: editData[0].assignedReviewerName,
          assigneeId: editData[0]?.assigneeId,
          assigneeName: editData[0].assigneeName,
          comment: editData[0].comment,
          descriptionOfResolution: editData[0].descriptionOfResolution,
          // implementationeffort: editData[0].implementationeffort,
          // isClientReported: this.supportReqObj.isClientReported.toString(),
          itemDescription: editData[0].itemDescription,
          itemNo: editData[0].itemNo,
          projectId: editData[0].projectId,
          projectName: editData[0].projectName,
          reproSteps: editData[0].reproSteps,
          revieweffort: editData[0].revieweffort,
          startDate: editData[0].startDate,
          solzStatus: editData[0].solzStatus?.Value,
          testingEnvironment: editData[0].testingEnvironment?.Value,
          // typeOfItem: editData[0].typeOfItem.Value,
          userPriority: editData[0].userPriority?.Value,
          workArea: editData[0].workArea?.Value,
          parentTitle: editData[0].parentTitle,
          parentId: editData[0].parentId,
          parentItemNo: editData[0].parentItemNo,
          // isBug: editData[0].isBug,
          sprintName: editData[0].sprintName,
          sprintId: editData[0].sprintId,
          linkedTask: editData[0].linkedTask,
          linkedTaskName: editData[0].linkedTaskName,
          linkedTaskId: editData[0].linkedTask,
        });

        //   }
        // })
      }

      else if (bugResp.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(bugResp.Message);
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }
  backBtn(){
    this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.updateToIds]);
  }

}
