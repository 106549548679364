import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { EmployeeDataModel } from '../_models/employee-data-model';
//import { environment } from '../../environments/environment';
import { environment } from '../../environments/environment.prod';
import { UserDataModel } from '../_models/user-data-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiURL: string = "";
  public userData: any;
  public authapiURL= ""

  constructor(public HttpClient: HttpClient,
    private storage: StorageMap,
    private router: Router,) {
    this.apiURL = environment.apiURL;
    this.authapiURL = environment.authapiURL
  }

  // postLogin(userModel: any): Observable<any> {
  //   return this.HttpClient.post(this.apiURL + 'EmployeeAuthorization/AuthorizeEmployee', userModel);

  // }
  postLogin(userModel: any): Observable<any> {debugger
    return this.HttpClient.post(this.authapiURL + 'Auth/Authenticate', userModel);

  }
  loadJSONFileData(jsonPath): Observable<any> {
    return this.HttpClient.get(jsonPath);
  }
  // postForgotPassword(userModel: any) {
  //   return this.HttpClient.post(this.apiURL + 'EmployeeAuthorization/ForgotPassword', userModel);
  // }

  getForgotPassword(email: any) {
    // return this.HttpClient.post(this.apiURL + 'EmployeeAuthorization/ForgotPassword', userModel);
    return this.HttpClient.get(this.authapiURL + 'Auth/ForgotPassword?userEmail=' + email )
  }

  async setEmployeeData(empModel: EmployeeDataModel) {
    this.storage.set('empDetails', empModel).subscribe(() => { });
  }
  async setUserData(userModel: UserDataModel) {
    this.storage.set('empDetails', userModel).subscribe(() => { });
  }

  postChangePassword(userModel: any) {
    return this.HttpClient.post(this.apiURL + 'EmployeeAuthorization/ChangePassword/', userModel);
  }

  logoutEmployee() {
    this.storage.clear().subscribe(() => { });
    localStorage.clear();
    this.router.navigate(['login']);
  }
  

  private crmId = new BehaviorSubject<any>(null); 
  latestCrmId = this.crmId.asObservable();
  
  updateDisplayPicture(userModel: any) {
    return this.HttpClient.post(this.apiURL + 'EmployeeAuthorization/UpdateDisplayPicture/', userModel);
  }

  updateCrmId(crmId: any) 
  {
    this.crmId.next(crmId);
   }
}

