import { Component, OnInit } from '@angular/core';
import { UserDataModel } from '../../../_models/user-data-model';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../_services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  userObj: UserDataModel;
  message: string = "";

  constructor(private HttpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public toastr: ToastrService) {debugger
  }

  ngOnInit(): void {debugger
    this.userObj = new UserDataModel();
  }

  forgotPassword(forgotForm: NgForm) {debugger
    var data = forgotForm.value;
    this.userObj.email = data.email;
    this.authService.getForgotPassword(data.email).subscribe((res: any) => {
      if (res.isSuccessful == true) {
        this.toastr.info(res.messageDetail.message, 'Info', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
      }
      else if (res.isSuccessful == false) {
        this.toastr.error(res.messageDetail.message, 'Error');
      }

    }, error => {
      this.toastr.error(error.message);
    });
  }
}
