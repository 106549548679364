import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRecordService {
  apiURL: string
  constructor(
    public HttpClient: HttpClient
  ) {
    this.apiURL = environment.apiURL
  }

  ngOnInit() {

  }

  // Get employee Project Allocation
  getemployeeProjectAllocation(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeRecord/GetemployeeProjectAllocation/' + id);
  }

  getEmployeeInventoryAllocation(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeRecord/GetEmployeeInventoryAllocation/' + id);
  }
  // ..............................HIRING MODEL API'S BEGNNING ............................................
  //APi For Hiring Model GRID
  getHiringPosition(): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeRecord/GetListOfOpenPosition/');
  }

  //APi For Hiring Model DATA
  getCandidateApplicationByEmployeeId(id: any): Observable<any> {
    return this.HttpClient.get(this.apiURL + 'EmployeeRecord/GetCandidateApplicationByEmployeeId?id=' + id);
  }

//Post API'S Of Create Condiate Application Form
  postapplyApplicationForm(obj:any): Observable<any> {
    return this.HttpClient.post(this.apiURL + 'EmployeeRecord/CreateCandidateApplication',obj);
  }
  // ..............................HIRING MODEL API'S END ............................................


}
