import { Component, Input, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LeaveApplicationDataModel } from '../../../_models/leave-application-data-model';
import { DashboardService } from '../../../_services/dashboard.service';

@Component({
  selector: 'app-dashboard-pie-chart',
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrls: ['./dashboard-pie-chart.component.css']
})
export class DashboardPieChartComponent implements OnInit { 
  @Input() employeeId: string;
  timeThisWeek: number;
  minTimeCurrentWeek: number;
  timeLastWeek: number;
  minTimeLastWeek: number;
  leaveObj: LeaveApplicationDataModel;
  thisWeekFlag: boolean = false;
  lastWeekFlag: boolean = false;

  constructor(
    public storage: StorageMap,
    public dashboardService: DashboardService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getLeaveBalance();
    this.getWorkHoursThisWeek();
  }

  async getWorkHoursThisWeek() {
    await this.dashboardService.getTimeLoggedThisWeek(this.employeeId).subscribe((res1: any) => {
      if (res1.ResponseCode == 100) { // success
        this.timeThisWeek = res1.Data;
        this.getMinWorkHoursThisWeek();

      }
      else if (res1.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(res1.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getMinWorkHoursThisWeek() {
    await this.dashboardService.getMinTimeLoggedThisWeek(this.employeeId).subscribe((res2: any) => {
      if (res2.ResponseCode == 100) { // success
        this.minTimeCurrentWeek = res2.Data;
        this.getWorkHoursLastWeek();

      }
      else if (res2.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(res2.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getWorkHoursLastWeek() {
    await this.dashboardService.getTimeLoggedLastWeek(this.employeeId).subscribe((res3: any) => {
      if (res3.ResponseCode == 100) { // success
        this.timeLastWeek = res3.Data;
        this.getMinWorkHoursLastWeek();

      }
      else if (res3.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(res3.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getMinWorkHoursLastWeek() {
    await this.dashboardService.getMinTimeLoggedLastWeek(this.employeeId).subscribe((res4: any) => {
      if (res4.ResponseCode == 100) { // success
        this.minTimeLastWeek = res4.Data;
        // this.getLeaveBalance();

      }
      else if (res4.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(res4.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getLeaveBalance() {
     this.dashboardService.getBalanceLeaveDashboard(this.employeeId).subscribe((res5: any) => {
      if (res5.ResponseCode == 100) { // success
        this.leaveObj = res5.Data;
        this.thisWeekFlag = false;
        this.lastWeekFlag = false;
        this.colorChange();

      }
      else if (res5.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(res5.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  colorChange() {
    if (this.timeThisWeek < this.minTimeCurrentWeek) {
      this.thisWeekFlag = true;
    }

    if (this.timeLastWeek < this.minTimeLastWeek) {
      this.lastWeekFlag = true;
    }
    this.spinner.hide();
  }

}

