import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from '../../../_services/feedback.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  feedBackSubmit: boolean = false;
  @Input() employeeId: string

  constructor(private fb: FormBuilder,
    public feedBackServices: FeedbackService,
    public toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.feedbackForm = this.fb.group({
      regardingTo: [''],
      feedBackTitle: ['', Validators.required],
      feedBackDescription: ['', Validators.required],
      // other: [''],
      feedBackDetails: ['']

    });
  }
  //Support Request Form Control
  get srf() {
    return this.feedbackForm.controls;
  }
  onSubmit() {
    debugger
    this.feedBackSubmit = true;
    if (this.feedbackForm.invalid) {

      return
    }
    let feedback =
    {
      "feedBackId": null,
      "feedBackTitle": this.feedbackForm.value.feedBackTitle,
      "feedBackDescription": this.feedbackForm.value.feedBackDescription,
      "regardingTo": {
        "value": 674180000,
        "label": "string"
      },
      "reportedBy": "string",
      "reportedById": this.employeeId,
      "status": {
        "value": 0,
        "label": "string"
      },
      "publishToEmp": false,
      "resolution": "string",
      "actionTakenBy": "string"
    }
    this.feedBackServices.createMyfeedBacks(feedback).subscribe((feedbackresp: any) => {
      if (feedbackresp.isSuccessful == true) {
        this.toastr.success(feedbackresp.messageDetail.message, 'Success')
        this.router.navigate(['/my-feedback'])
      }
      else if (feedbackresp.isSuccessful == false) {
        this.toastr.error(feedbackresp.messageDetail.message, 'Error')
      }


    })
  }

}
