import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  authApiURL: string = ""

  constructor(public HttpClient: HttpClient,
  ) {
    this.authApiURL = environment.authapiURL
  }

  createMyfeedBacks(feedbackObj: any) {
    return this.HttpClient.post(this.authApiURL + 'Feedbacks/CreateMyFeedBacks', feedbackObj)
  }

  GetMyFeedbacksListByEmpId(id: any) {
    return this.HttpClient.get(this.authApiURL + 'Feedbacks/GetMyFeedbacksListByEmpId?=' + id)
  }
  GetMyFeedbacksByFeedBackId(id: any) {
    return this.HttpClient.get(this.authApiURL + 'Feedbacks/GetMyFeedbacksByFeedBackId?FeedBackId=' + id)
  }
}
