import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LeaveApplicationDataModel } from '../../../../_models/leave-application-data-model';
import { LeaveService } from '../../../../_services/leave.service';
import { Sort } from '../../../../_services/sort.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../_services/auth.service';
import { EmployeeDataModel } from '../../../../_models/employee-data-model';


@Component({
  selector: 'app-leave-balance-details',
  templateUrl: './leave-balance-details.component.html',
  styleUrls: ['./leave-balance-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LeaveBalanceDetailsComponent implements OnInit {
  leaveObj: LeaveApplicationDataModel;
  totalLop: number;
  Duration: string;
  leaveAvailed: number;
  leaveId: string;
  userData: any;
  empId: string = "";
  empDetails: EmployeeDataModel;

  constructor(
    private router: Router,
    public HttpClient: HttpClient,
    public authService: AuthService,
    public leaveService: LeaveService,
    private sort: Sort,
    public storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) { }

    

  ngOnInit(): void {
   
    this.empId = localStorage.getItem('empId');
  //  this.route.params.subscribe((params: any) => {
      
  //     this.empId = params.empId;
  //   });
    this.spinner.show();
    this.leaveObj = new LeaveApplicationDataModel();
    this.route.paramMap.subscribe(params => {
      this.leaveId = params.get('leaveId');
    });
    this.getData(); // getting data from local storage api and setting it in our local variable.
  }
  
 
  async getData() {
    await this.storage.get('empDetails').subscribe((empLeaveBalDet:any) => {
      this.userData = empLeaveBalDet.userProfile;
      this.getLeaveBalDet();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //getting data from database.
  async getLeaveBalDet() {
    await this.leaveService.getLeaveBalanceDetails(this.leaveId).subscribe((res: any) => {
      if (res.ResponseCode == 100) {
        this.leaveObj = res.Data; // setting response data into leaveObj.

        // setting data in local properties to display
        this.Duration = this.leaveObj.Month.Label + ',' + this.leaveObj.Year.Label;
        this.totalLop = this.leaveObj.TotalLossOfPay + this.leaveObj.LopLates + this.leaveObj.LopLowHrsLess8 + this.leaveObj.LopLowHrsLess3 + this.leaveObj.LopLowHrs3_5;
        this.leaveAvailed = this.leaveObj.earnleaveavailed + this.leaveObj.medicalleaveavailed + this.leaveObj.optionalleavesavailed + this.leaveObj.otherleavesavailed + this.totalLop;
      }
      else if (res.ResponseCode == 999) {
        this.toastr.error(res.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
}
