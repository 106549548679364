import { Component, Input, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../_services/dashboard.service';
import { EmployeeDataModel } from '../../../_models/employee-data-model';

@Component({
  selector: 'app-skills-allocation',
  templateUrl: './skills-allocation.component.html',
  styleUrls: ['./skills-allocation.component.scss']
})
export class SkillsAllocationComponent implements OnInit {
  @Input() employeeId: string
  userData: any;
  skillsAllocationyData: any;

  constructor(
    public storage: StorageMap,
    public dashboardService: DashboardService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show()
    this.getdata()
  }

  async getdata() {
    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myInvoice: EmployeeDataModel) => {

      this.userData = myInvoice.userProfile;
      this.employeeId = this.userData.userId

      this.getTechSkills(this.employeeId)
    })
  }
  async getTechSkills(employeeId) {
    await this.dashboardService.getEmployeeTechSkills(employeeId).subscribe((skillResp: any) => {
      if (skillResp.ResponseCode == 100) { //success
        this.spinner.hide();
        this.skillsAllocationyData = skillResp.Data.Skills;

      }
      else if (skillResp.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(skillResp.Message, 'Error');
      }
      else if (skillResp.ResponseCode == 900) {        
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

}
