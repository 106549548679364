import { Component, OnInit } from '@angular/core';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { ToDosService } from '../../../_services/to-dos.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-observation',
  templateUrl: './my-observation.component.html',
  styleUrls: ['./my-observation.component.scss']
})
export class MyObservationComponent implements OnInit {

  userData: EmployeeDataModel;
  contactId: any;
  empId: any;
  custTableFlag: number = 0;
  empTableFlag: number = 0;
  allProjectList: Array<ProjectDataModel> = [];
  toDoList: Array<ToDosDataModal> = [];
  toDosObj: ToDosDataModal;
  ContactLoggedIn: boolean;
  isManager: any;
  heading: string;
  parameter: string;
  fieldsToExport: Array<any> = [];
  isBugList: any;
  ToggleFlag: boolean;

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public supportReqService: SupportRequestService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.spinner.show();
    this.toDosObj = new ToDosDataModal();
    this.parameter = "";
    this.getData(); // getting data from local storage api and setting it in our local variable.


  }

  async getData() {

    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myToDos: EmployeeDataModel) => {
      this.userData = myToDos.userProfile;


      // If Soluzione Emp/Manager logged in
      if (this.userData.userId) {
        this.ContactLoggedIn = false;
        this.isManager = this.userData?.isManager;
        this.empId = this.userData?.userId
        this.GetMyObservationToDos();
        this.createExportList();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }




  async GetMyObservationToDos() {
    debugger
    await this.toDosService.GetMyObservationToDos(this.empId).subscribe((res4: any) => {
      if (res4.ResponseCode == 100) { // success

        this.toDoList = res4.Data;
        this.spinner.hide();
        this.createExportList();
      }
      else if (res4.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(res4.Message, 'Error');
      }
      else if (res4.ResponseCode == 217) {
        this.spinner.hide();
        this.toastr.error(res4.Message, 'Error');
      }
      else if (res4.ResponseCode == 900) {
        this.spinner.hide();
        // this.toastr.error(res4.Message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    })

  }

  //Toggle for Expand And collapsed Table Row Data
  showHideToggle() {
    this.ToggleFlag = !this.ToggleFlag;
  }

  // sending to-do id to new to do page.
  updateToDo(id: string) {
    this.router.navigate(['/ESSPortal/update-raise-bug-all-project/' + id]);
  }

  // creating user readable list.
  createExportList() {


    for (let i = 0; i < this.toDoList.length; i++) {
      var ex = {
        Item_No: "",
        Created_On: null,
        Title: "",
        ItemsType: "",
        WorkType: "",
        Project_Name: "",
        Item_Description: "",
        Repro_Steps: "",
        Parent_Title: "",
        User_Priority: null,
        Type_Of_Item: null,
        Date_Moved: null,
        Testing_Environment: null,
        Expected_Res_Date: null,
        Comment: "",
        Description_Of_Resolution: "",
        Work_Status: null,
        Is_Client_Reported: "",
        Assignee_Name: "",
        Assigned_Reviewer_Name: "",
        Implementation_Effort: 0,
        Start_Date: null,
        Review_Effort: 0,
        isBug: ""
      }

      ex.Item_No = this.toDoList[i].itemNo;
      ex.Created_On = this.toDoList[i].createdon;
      ex.Title = this.toDoList[i].title;
      ex.ItemsType = this.toDoList[i].typeOfItems?.Label;
      ex.WorkType = this.toDoList[i].typeOfItem?.Label;
      ex.Project_Name = this.toDoList[i].projectName;
      ex.Item_Description = this.toDoList[i].itemDescription;
      ex.Repro_Steps = this.toDoList[i].reproSteps;
      ex.Parent_Title = this.toDoList[i].parentTitle;
      ex.User_Priority = this.toDoList[i].userPriority?.Label;
      // ex.Type_Of_Item = this.toDoList[i].typeOfItem?.Label;
      ex.Date_Moved = this.toDoList[i].dateMoved;
      ex.Testing_Environment = this.toDoList[i].testingEnvironment?.Label;
      ex.Expected_Res_Date = this.toDoList[i].expectedResDate;
      ex.Comment = this.toDoList[i].comment;
      ex.Description_Of_Resolution = this.toDoList[i].descriptionOfResolution;
      ex.Work_Status = this.toDoList[i].solzStatus?.Label;


      if (this.toDoList[i].isClientReported == false || this.toDoList[i].isClientReported == null) {
        ex.Is_Client_Reported = "No";
      }

      else {
        ex.Is_Client_Reported = "Yes";
      }
      ex.Assignee_Name = this.toDoList[i].assigneeName;
      ex.Assigned_Reviewer_Name = this.toDoList[i].assignedReviewerName;
      ex.Implementation_Effort = this.toDoList[i].implementationeffort;
      ex.Start_Date = this.toDoList[i].startDate;
      ex.Review_Effort = this.toDoList[i].revieweffort;
      // ex.isBug = this.toDoList[i].isBug
      if (this.toDoList[i].isBug == false || this.toDoList[i].isBug == null) {
        ex.isBug = "No";
      }
      else {
        ex.isBug = "Yes";
      }


      this.fieldsToExport[i] = ex;
    }
  }

  exportToExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.fieldsToExport);
      const workbook = { Sheets: { 'Exporting_Tasks': worksheet }, SheetNames: ['Exporting_Tasks'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Exporting_Tasks");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }
}
