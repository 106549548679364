import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataModel } from '../../../_models/user-data-model';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-generate-password',
  templateUrl: './generate-password.component.html',
  styleUrls: ['./generate-password.component.scss']
})
export class GeneratePasswordComponent implements OnInit {

  userObj: UserDataModel;
  message: string = "";


  constructor(private HttpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public toastr: ToastrService) {debugger
  }

  ngOnInit(): void {debugger
    this.userObj = new UserDataModel();
  }

  forgotPassword(forgotForm: NgForm) {debugger
    var data = forgotForm.value;
    this.userObj.email = data.email;
    this.userObj.password = data.password;
    this.authService.getForgotPassword(data.email).subscribe((res: any) => {
      if (res.isSuccessful == true) {
        this.toastr.info(res.messageDetail.message, 'Info', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
      }
      else if (res.isSuccessful == false) {
        this.toastr.error(res.messageDetail.message, 'Error');
      }

    }, error => {
      this.toastr.error(error.message);
    });
  }
}
