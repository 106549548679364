import { BaseEntityDataModel } from './base-entity-data-model';
import { OptionSetDataModel } from './option-set-data-model';
import { UserDataModel } from './user-data-model';

export class LeaveApplicationDataModel {
  employee: UserDataModel;
  ID: string;// created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  Name: string; // created by Udbhav for API DM compatability as the DM there inherits Base Entity.
  leaveType: OptionSetDataModel;
  typeofHalfDayLeave: OptionSetDataModel;
  leaveStartDate:any;
  leaveEndDate: any;
  totalDaysofLeave: number;
  appliedOn: Date;
  actionedOn: Date;
  approver: UserDataModel;
  Status: OptionSetDataModel;
  leaveDayType: OptionSetDataModel;
  resultdate: boolean;
  leaveCancellationMessage: string;
  
  // Get details of yearly leave balance record
  Month: OptionSetDataModel;
  EarnedLeave: number;
  MedicalLeave: number;
  OptionalLeaveBalance: number;
  TotalPayDays: number;
  Year: OptionSetDataModel;

  // Get details of monthly leave balance record
  //public decimal DeficientHours { get; set; }
  OptionalLeaveRemaining: number;
  otherleavesremaining: number;
  otherleavesbalance: number;
  otherleavesavailed: number;
  optionalleavesavailed: number;
  medicalleaveremaining: number;
  medicalleaveavailed: number;
  earnleaveremaining: number;
  earnleaveavailed: number;

  //public decimal LopAbsent { get; set; }
  NoOfLate: number;
  LopLates: number;
  TotalLowHrsLess8: number;
  LopLowHrsLess8: number;
  TotalLowHrs3_5: number;
  LopLowHrs3_5: number;
  TotalLowHrsLess3: number;
  LopLowHrsLess3: number;
  DeficientHours: any;
  
  //public decimal LopForHalfDay { get; set; }
  //public decimal LopForFullDay { get; set; }
  TotalLossOfPay: number;
  //public string NoOfHalfDay { get; set; }
  //public string NoOfFullDay { get; set; }

  Date: Date;

  //datamodal for dashboard
  totalDays: string;
  totallopleave: number;
  months: string;
  leaveApplicationId: any;
  control: any;
}
