import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-employee-goals',
  templateUrl: './update-employee-goals.component.html',
  styleUrls: ['./update-employee-goals.component.scss']
})
export class UpdateEmployeeGoalsComponent implements OnInit {
  empGoalsForm: FormGroup;
  updateGoalsdata: any;
  updateGoalsId: string;
  EmployeeGoalslist: any;
  empId: string;
  EmployeeGoalsUpdateId: any;
  fieldRequiredFlag: boolean;
  statusresionval: any;
  status: any;
  goalFormSubmitted: boolean = false;
  buttonFlag: boolean = false;
  filterStatus: []
  isShow: boolean = false
  statusresionUpdate: any;

  constructor(private fb: FormBuilder,
    private router: Router,
    private PerformanceService: PerformanceReviewService,
    public storage: StorageMap,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { this.empId = localStorage.getItem("empId") }

  ngOnInit(): void {
    this.spinner.show();
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateGoalsId = this.route.snapshot.paramMap.get('id');
    }

    this.empGoalsForm = this.fb.group({
      EmployeeName: [''],
      PerformanceYear: [''],
      Goals: [''],
      achievedDate: [''],
      ExpOutcome: [''],
      percentageAchieved: [''],
      statusReason: ['', Validators.required],
      // employeeComments: ['', Validators.required],
      newComments: [''],
      managerComments: [''],
      previousComments: ['']

    });
    this.empGoalsForm.patchValue({
      statusReason: 674180002

    });

    // this.getgolsforUpdation()
   
    this.dropdownOfStatus()
  }


  //empGoalsForm Form Control
  get egl() {
    return this.empGoalsForm.controls;
  }

  checkValue(event) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
  }
  dropdownOfStatus() {
    this.PerformanceService.getDropDownDataOfStatus().subscribe((responce: any) => {
      if (responce.ResponseCode == 100) {
        this.status = responce.Data
        
        this.status.forEach(element => {
          
          if(element.Value == 674180002){
            this.filterStatus = responce.Data.filter(status => status.Value === 674180002|| status.Value === 1);
          }
          else{
            this.filterStatus = this.status
          }
        });
        this.GetGoalListsById()

      }
      else{
        this.GetGoalListsById()
      }
    })
  }
  OnChangestatusReason(status: any) {
    this.statusresionval = status
  }

  //##########################List Of All Employee Goals###########################################
  GetGoalListsById() {
    this.PerformanceService.GetGoalListsById(this.empId).subscribe((Responcelist: any) => {
      if (Responcelist.ResponseCode == 100) {

        this.EmployeeGoalslist = Responcelist.Data
        this.EmployeeGoalsUpdateId = Responcelist.Data.ID
        this.getgolsforUpdation(this.updateGoalsId);
        // this.spinner.hide();

      }
      else if (Responcelist.ResponseCode == 999) {
        this.toastr.error(Responcelist.Message, 'Error');
        this.spinner.hide();
      }
    })
  }

  //****************Patch Values By List Id On Update/ View Form   */
  getgolsforUpdation(positionDetails) {
    this.EmployeeGoalslist.forEach(element => {
      const date = new Date(element.AchievebyDate); // Assuming element.AchievebyDate is a valid date string
      const formattedDate = date.toISOString().split('T')[0]; // Formats to YYYY-MM-DD

      if (element.ID == positionDetails) {
        // format of expected resolution date so as to display in form for Employee.
        this.empGoalsForm.patchValue({
          EmployeeId: this.empId,
          ExpOutcome: element.ExpOutcome,
          achievedDate: formattedDate,
          previousComments: element.Comment,
          managerComments: element.ManagerComment,
          Goals: element.Goal,
          percentageAchieved: element.PercentAchieved,
          statusReason: element.StatusReason.Value,

        })
        this.statusresionUpdate = element.StatusReason.Value
        if (element.StatusReason.Value == 674180002) {
          this.filterStatus = this.status?.filter(filterElement => {
            return filterElement.Label === 'In Progress' || filterElement.Label === 'New' ||
            filterElement.Value === 1 || filterElement.Value === 674180002;
          });
        }
        else{
          this.filterStatus = this.status
        }
        //if status is 	Achieved	674180000 Over Due	674180001 Incomplete	674180004 Inactive	2 In Progress	1 then disable status field
        if (element.StatusReason.Value == 1 || element.StatusReason.Value == 674180000 || element.StatusReason.Value == 674180001 || element.StatusReason.Value == 674180004 || element.StatusReason.Value == 2) {
          this.empGoalsForm.get('statusReason').disable();
        }

        //if status is ACHIEVED then  Fully Locked form and hide Save Button
        if (element.StatusReason.Value == 674180000) {
          this.empGoalsForm.disable();
          this.buttonFlag = true;
        }
        // if status is INACTIVE then   Locked form field percentageAchieved and statusReason 
        if (element.StatusReason.Value == 674180004) {
          this.empGoalsForm.get('statusReason').disable();
          this.empGoalsForm.get('percentageAchieved').disable();
        }
        //if status is OVERDUE then  loack Change Status field 
        if (element.StatusReason.Value == 674180001) {
          this.empGoalsForm.get('statusReason').disable();
        }
        this.spinner.hide();

      }

    });

  }

  submit() { 
    this.goalFormSubmitted = true;
    if (this.empGoalsForm.invalid) {
      // Mark all form controls as touched to trigger validation messages
      Object.values(this.empGoalsForm.controls).forEach(control => {
        control.markAsTouched();
        this.fieldRequiredFlag = true;
      });
      return;

    }
    let obj = {
      EmployeeId: this.empId,
      ExpOutcome: this.empGoalsForm.value.ExpOutcome,
      AchievebyDate: this.empGoalsForm.value.achievedDate,
      previousComments: this.empGoalsForm.value.employeeComments,
      ManagerComment: this.empGoalsForm.value.managerComments,
      Goal: this.empGoalsForm.value.Goals,
      // PerformanceYear: this.empGoalsForm.value.PerformanceYear,    
      PercentAchieved: this.empGoalsForm.value.percentageAchieved,
      Comment: this.empGoalsForm.value.newComments,
      id: this.updateGoalsId,
      StatusReason: {

        Value: this.empGoalsForm.controls.statusReason.value
      }
      

    }

    //#############################  Post Updated Details ######################################### 
    this.PerformanceService.postgetgoalsDetailsUpdation(obj).subscribe({
      next: (responce: any) => {
        if (responce.ResponseCode == 100) {
          this.toastr.success(responce.message, 'Record Update Successfully', {//Success
            positionClass: 'toast-top-full-width',
          });
          this.router.navigate(['/ESSPortal/employee-goals-list']);
          this.spinner.hide();

        }

        else if (responce.ResponseCode == 999) {
          this.toastr.error(responce.Message, 'Error');
          this.spinner.hide();
        }
      }
    })

  }

}
