import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService } from '../../../_services/feedback.service';

@Component({
  selector: 'app-update-feed-back',
  templateUrl: './update-feed-back.component.html',
  styleUrls: ['./update-feed-back.component.scss']
})
export class UpdateFeedBackComponent implements OnInit {
  feedbackId: string;
  feedbackForm: FormGroup;
  Updatefeedback: any;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService
  ) 
  {debugger
    if (this.route.snapshot.paramMap.get('id')) {
      this.feedbackId = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit(): void {
    this.feedbackForm = this.fb.group({
      regardingTo: [''],
      feedBackTitle: [''],
      feedBackDescription: [''],
      reportedBy: [''],
      reportedOn:[''],
      ResolvedBy: [''],
      resolution: [''],
      owner: ['']
      // other: ['']


    });
    this.GetMyFeedbacksByFeedBackId();
  }

  GetMyFeedbacksByFeedBackId(){
    this.feedbackService.GetMyFeedbacksByFeedBackId(this.feedbackId).subscribe((feedbckResp:any)=>{
      this.Updatefeedback = feedbckResp.data
      console.log('responce',this.Updatefeedback)
    })
  }

  onSubmit() {

  }

  //Support Request Form Control
  get srf() {
    return this.feedbackForm.controls;
  }



}
