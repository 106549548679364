import { Component, Input, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../_services/dashboard.service';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { EmployeeRecordService } from '../../../_services/employee-record.service';

@Component({
  selector: 'app-project-allocation',
  templateUrl: './project-allocation.component.html',
  styleUrls: ['./project-allocation.component.scss']
})
export class ProjectAllocationComponent implements OnInit {
@Input()employeeId : string
  userData: EmployeeDataModel;
  projectAllocationyData: any;
  constructor(
    public storage: StorageMap,
    public employeeRecordService: EmployeeRecordService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show()
    this.getdata()
  }

  async getdata(){
    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myInvoice: EmployeeDataModel) => {
      
      this.userData = myInvoice.userProfile;
      this.employeeId = this.userData.userId
     
      this.getProjectAllocation(this.employeeId)
    })
  }
  async getProjectAllocation(employeeId) {
    await this.employeeRecordService.getemployeeProjectAllocation(employeeId).subscribe((projectResp: any) => {
      if (projectResp.ResponseCode == 100) { //success
        this.spinner.hide();
        this.projectAllocationyData = projectResp.Data;
        
      }
      else if (projectResp.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(projectResp.Message, 'Error');
      }
      else if (projectResp.ResponseCode == 217) {
        this.spinner.hide();
        // this.toastr.error(projectResp.Message, 'Error');
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

}
